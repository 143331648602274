<template>
  <div>
    <v-btn class="w-100 mb-3" color="white" v-on:click="logInWithFacebook">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.1113 12.0001C23.1113 5.64878 17.9626 0.500055 11.6113 0.500055C5.26005 0.500055 0.111328 5.64878 0.111328 12.0001C0.111328 17.74 4.31671 22.4976 9.81445 23.3604V15.3243H6.89453V12.0001H9.81445V9.46646C9.81445 6.58427 11.5313 4.99224 14.1582 4.99224C15.4164 4.99224 16.7324 5.21685 16.7324 5.21685V8.04693H15.2823C13.8537 8.04693 13.4082 8.9334 13.4082 9.84284V12.0001H16.5977L16.0878 15.3243H13.4082V23.3604C18.9059 22.4976 23.1113 17.74 23.1113 12.0001Z"
          fill="#1877F2"
        />
      </svg>
      <span class="ms-2">{{ $t("continueWithFacebook") }}</span>
    </v-btn>
  </div>
</template>
<script>
import { mapActions } from "vuex"
import GlobalMixin from "@/GlobalMixin.js"
export default {
  name: "facebookLogin",
  mixins: [GlobalMixin],
  methods: {
    ...mapActions(["StoreUser"]),
    FaceBookAuthonticate(authResponse) {
      let self = this
      self.ShowLoading()
      let url = process.env.VUE_APP_APIEndPoint + "auth/social-sign-in/"
      self.model = {
        code: authResponse.accessToken,
        register_type: "facebook_student",
      }
      self.axios
        .post(url, self.model, self.headers)
        .then(function (res) {
          if (res.data.success) {
            self.model = {}
            self.notify(self.$t("login success"), "success")
            self.StoreUser(res.data)
            self.$router.push("/BookedClasses")
          } else {
            self.notify(self.$t(res.data.Messages[0].Message), "error")
          }
        })
        .catch(function (err) {
          self.displayError(err)
        })
        .finally(function () {
          //self.$router.push({ name: 'Home' });
          return self.HideLoading()
        })
    },
    async logInWithFacebook() {
      //await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      //await this.initFacebook();
      let self = this
      window.FB.login(function (response) {
        if (response.authResponse) {
          self.FaceBookAuthonticate(response.authResponse)
          // Now you can redirect the user or do an AJAX request to
          // a PHP script that grabs the signed request from the cookie.
        } else {
          self.notify("User cancelled login", "error")
        }
      })
      return false
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FaceBookAppId, //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v13.0",
        })
      }
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/en_US/sdk.js"
      fjs.parentNode.insertBefore(js, fjs)
    },
  },
  created() {
    this.loadFacebookSDK(document, "script", "facebook-jssdk")
    this.initFacebook()
  },
}
</script>
<style>
.button {
  color: white;
  min-width: 150px;
  background-color: #000000a1;
  height: 2.5rem;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 0.8rem;
}
</style>
