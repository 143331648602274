<template>
  <div style="z-index: 100000">
    <v-dialog
      v-model="showAlert"
      elevation="2"
      transition="scale-transition"
      max-width="500"
      persistent
    >
      <v-card class="card">
        <v-card-title
          ><v-icon v-if="alertIcon === 'error'" class="icon" color="red"
            >mdi-close-circle-outline
          </v-icon>
          <v-icon v-if="alertIcon === 'success'" class="icon" color="green">
            mdi-check-circle-outline
          </v-icon>
        </v-card-title>
        <v-card-text class="card-content">
          <h2>{{ alertTitle }}</h2>
          <p v-html="alertText"></p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Alert",
  updated() {
    if (this.showAlert) {
      setTimeout(() => {
        this.$store.dispatch("toggleAlert", false)
      }, this.alertTimer)
    }
  },
  computed: {
    ...mapGetters({
      showAlert: "GET_SHOW_ALERT",
      alertIcon: "GET_ALERT_ICON",
      alertTitle: "GET_ALERT_TITLE",
      alertText: "GET_ALERT_TEXT",
      alertTimer: "GET_ALERT_TIMER",
      isAlertPermanent: "GET_IS_ALERT_PERMANENT",
    }),
  },
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .icon {
    font-size: 5rem;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 1.5rem;
      text-align: center;
      line-height: 1.1;
    }
  }
}
</style>
