<template>
  <!--<button class="btn btn-light border border-dark google-signin-button" type="button" v-google-signin-button="clientId"> <img src="@/assets/img/google.png" width="30" height="30"> Continue with Google</button>-->

  <div id="buttonDiv" class="mb-3"></div>
</template>
<script>
import GoogleSignInButton from "vue-google-signin-button-directive"
import { mapActions } from "vuex"
import GlobalMixin from "@/GlobalMixin.js"
export default {
  directives: {
    GoogleSignInButton,
  },
  mixins: [GlobalMixin],
  data: () => ({
    clientId: process.env.VUE_APP_GoogleAppId,
  }),
  mounted() {
    var self = this
    google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GoogleAppId,
      callback: self.GoogleAuthonticate,
    })
    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { type: "button", size: "large", locale: self.$i18n.locale } // customization attributes
    )
    google.accounts.id.prompt() // also display the One Tap dialog
  },
  methods: {
    ...mapActions(["StoreUser"]),
    GoogleAuthonticate(authResponse) {
      let self = this
      self.ShowLoading()
      let url = process.env.VUE_APP_APIEndPoint + "auth/social-sign-in/"
      self.model = {
        code: authResponse.credential,
        register_type: "google_student",
        cookiePolicy: "single_host_origin",
        plugin_name: "alkuttab",
      }
      self.axios
        .post(url, self.model, self.headers)
        .then(function (res) {
          if (res.data.success) {
            self.model = {}
            self.notify(self.$t("login success"), "success")
            self.StoreUser(res.data)
            self.$router.push("/BookedClasses")
          } else {
            self.notify(self.$t(res.data.Messages[0].Message), "error")
          }
        })
        .catch(function (err) {
          self.displayError(err)
        })
        .finally(function () {
          // self.$router.push({ name: 'Home' });
          return self.HideLoading()
        })
    },
    OnGoogleAuthSuccess(idToken) {
      // Receive the idToken and make your magic with the backend
      let self = this
      console.log("google auth success", idToken)
      self.GoogleAuthonticate(idToken)
    },
    OnGoogleAuthFail(error) {
      console.log("google auth error", error)
    },
  },
}
</script>

<style lang="scss" scoped>
.google-signin-button {
  color: white;
  background-color: white;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.social-item {
  background-color: white;
  box-shadow: 2px 3px 5px 0px #888888;
}
.LgbsSe-Bz112c {
  width: 25px;
  height: 25px;
  margin-top: -12px;
  margin-left: -5px;
}
</style>
