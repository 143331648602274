<template>
  <div class="nav-container">
    <SupportPalestine
      :institute_name="configs.institute_name.config_value"
    ></SupportPalestine>
    <nav
      :class="navbarClass"
      class="d-flex align-items-center justify-content-between"
    >
      <router-link
        :to="{ name: 'FancyHome' }"
        class="logo d-flex align-items-center"
      >
        <img :src="instituteLogo" alt="logo" />
      </router-link>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="drawer"
        :class="drawing"
      ></v-app-bar-nav-icon>
      <ul class="menu-list">
        <li
          :class="navItemClass"
          v-for="headerList in headerData.headerLinks"
          :key="headerList.id"
        >
          <router-link
            v-if="
              headerList.componentName &&
              !hideForKuttab(headerList.hideForAlkuttab)
            "
            :to="{ name: headerList.componentName }"
            :class="{ disabled: headerList.isDisabled }"
          >
            {{ $t("header." + headerList.name) }}
          </router-link>
          <a :href="headerList.link" v-else-if="headerList.link">
            {{ $t("header." + headerList.name) }}
          </a>
          <span
            v-else-if="headerList.subMenu"
            :class="subMenuNavItemClass"
            class=""
          >
            <span
              :style="{ color: isScrolled ? 'rgb(56, 56, 56)' : '#fff' }"
              class="submenu-nav-item-text"
            >
              {{ $t("header." + headerList.name) }}
            </span>
            <v-icon
              :color="isScrolled ? 'rgb(56, 56, 56)' : '#fff'"
              class="submenu-nav-item-icon"
              >mdi-chevron-down
            </v-icon>
          </span>
          <template v-if="headerList.subMenu && headerList.subMenu.length">
            <ul class="sub-menu-list">
              <li v-for="subLink in headerList.subMenu" :key="subLink.id">
                <router-link :to="{ name: subLink.componentName }">
                  {{ $t("header." + subLink.name) + instituteName }}
                </router-link>
              </li>
            </ul>
          </template>
        </li>
      </ul>
      <div :class="scrollActions" class="actions">
        <CurrencyList />
        <SwitchLang :isScrolled="isScrolled" class="lang-btn" />
        <template v-if="state.isAuthenticated">
          <button class="user-menu position-relative">
            <v-icon :color="isScrolled ? 'rgb(56, 56, 56)' : '#fff'"
              >mdi-account
            </v-icon>
            <span class="full-name">
              {{ fullname }}
            </span>
            <v-icon :color="isScrolled ? 'rgb(56, 56, 56)' : '#fff'"
              >mdi-chevron-down
            </v-icon>
            <ul class="user-sub-menu">
              <li
                v-for="menuLink in headerData.header_menu[
                  state.StateUser.user.user_type.includes('student')
                    ? 'student'
                    : state.StateUser.user.user_type
                ]"
                :key="menuLink.id"
              >
                <router-link :to="{ name: menuLink.componentName }">{{
                  $t("header." + menuLink.name)
                }}</router-link>
              </li>
              <li>
                <Logout class="logout-btn" />
              </li>
            </ul>
          </button>
        </template>
        <template v-else>
          <v-btn id="login-btn" class="login-btn" v-on:click="openLoginModal">
            {{ $t("header.login") }}
          </v-btn>
          <v-btn
            id="register-btn"
            class="register-btn"
            v-on:click="openRegisterModal"
          >
            {{ $t("header.signUpForFree") }}
          </v-btn>
        </template>
      </div>
      <!-- Drawer for small screen -->
      <v-navigation-drawer
        v-model="drawer"
        app
        fixed
        temporary
        overlay-color="#000"
        overlay-opacity="0.7"
        disable-resize-watcher
      >
        <v-list nav dense>
          <v-list-item-group>
            <template v-if="!state.isAuthenticated">
              <v-list-item>
                <v-btn
                  id="login-btn"
                  class="login-btn"
                  v-on:click="openLoginModal"
                >
                  {{ $t("header.login") }}
                </v-btn>
              </v-list-item>
              <hr />
            </template>
            <template v-else>
              <div>
                <v-expansion-panels accordion flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      {{ fullname }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-for="menu_list in headerData.header_menu[
                        state.StateUser.user.user_type
                      ]"
                      :key="menu_list.id"
                    >
                      <router-link :to="{ name: menu_list.componentName }">
                        {{ $t("header." + menu_list.name) }}
                      </router-link>
                    </v-expansion-panel-content>
                    <v-expansion-panel-content>
                      <Logout class="logout-btn" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <!-- <hr /> -->
              </div>
            </template>
            <div
              v-for="headerList in headerData.headerLinks"
              :key="headerList.id"
            >
              <v-expansion-panels
                v-if="headerList.subMenu && headerList.subMenu.length"
                accordion
                flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ $t("header." + headerList.name) }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-for="subMenuLink in headerList.subMenu"
                    :key="subMenuLink.id"
                  >
                    <router-link :to="{ name: subMenuLink.componentName }">
                      {{ $t("header." + subMenuLink.name) + instituteName }}
                    </router-link>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-list-item
                v-if="!hideForKuttab(headerList.hideForAlkuttab)"
                class="drawer-list-item"
              >
                <router-link
                  v-if="headerList.componentName"
                  :to="{ name: headerList.componentName }"
                  :class="{ disabled: headerList.isDisabled }"
                >
                  {{ $t("header." + headerList.name) }}
                </router-link>
                <a :href="headerList.link" v-else-if="headerList.link">
                  {{ $t("header." + headerList.name) }}
                </a>
              </v-list-item>
              <!-- <hr /> -->
            </div>
            <v-list-item class="d-flex justify-content-between">
              <SwitchLang :drawer="true" />
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <LoginModal></LoginModal>
    </nav>
  </div>
</template>
<script>
import SwitchLang from "@/components/SwitchLang.vue"
import Logout from "@/components/Logout.vue"
import GlobalMixin from "@/GlobalMixin.js"
import { header } from "@/data/header.js"
import LoginModal from "@/components/global/LoginModal.vue"
import CurrencyList from "./CurrencyList.vue"
import SupportPalestine from "./SupportPalestine.vue"

export default {
  name: "Header",
  mixins: [GlobalMixin],
  components: {
    SwitchLang,
    Logout,
    CurrencyList,
    LoginModal,
    SupportPalestine,
  },
  data() {
    return {
      state: this.$store.getters,
      headerData: header,
      drawer: false,
      configs: JSON.parse(localStorage.getItem("configs")),
      institute: JSON.parse(localStorage.getItem("institute_configs")),
      isScrolled: false,
    }
  },
  /**
   * Initialize Component
   */
  mounted() {
    // this.getconfigurations();
    window.addEventListener("localstorageChange", event => {
      this.configs = JSON.parse(event.detail.storage.configs)
    })
    window.addEventListener("scroll", this.handleScroll)
  },
  methods: {
    handleScroll() {
      // Change isScrolled when user scrolls down beyond a threshold (e.g., 80px)
      this.isScrolled = window.scrollY > 80
    },
    openLoginModal() {
      window.dispatchEvent(
        new CustomEvent("loginModalChange", {
          detail: {
            opened: true,
            tab: 0,
          },
        })
      )
    },
    openRegisterModal() {
      window.dispatchEvent(
        new CustomEvent("loginModalChange", {
          detail: {
            opened: true,
            tab: 1,
          },
        })
      )
    },
    hideForKuttab(isHideForKuttab) {
      if (isHideForKuttab) {
        if (this.institute.id === 1) {
          return true
        }
        return false
      }
      return false
    },
  },
  computed: {
    // navbar scroll update
    navbarClass() {
      return this.isScrolled ? "bg-white shadow-lg" : "bg-home"
    },
    navItemClass() {
      return this.isScrolled ? "nav-item-class" : "text-white"
    },
    subMenuNavItemClass() {
      return this.isScrolled ? "submenu-nav-item-scroll" : "submenu-nav-item"
    },
    scrollActions() {
      return this.isScrolled ? "scroll-actions" : ""
    },
    drawing() {
      return this.isScrolled ? "scrolled-drawer" : "drawer"
    },
    /**
     * Check whether user is student or not
     */
    isStudent() {
      return this.$store.getters.StateUser.user.user_type === "student"
    },
    /**
     * Gets full name of the logged user
     */
    fullname() {
      return `${this.$store.getters.StateUser.user.first_name} ${this.$store.getters.StateUser.user.last_name}`
    },
    /**
     * Gets institute logo for institute
     */
    instituteLogo() {
      return this.configs?.logo?.config_value
    },
    /**
     * Gets institute name for institute
     */
    instituteName() {
      return this.configs?.institute_name?.config_value
    },
  },
  watch: {
    /**
     * Watch for language change
     */
    "$i18n.locale"() {
      this.headers.headers["accept-language"] = localStorage.lang
      this.getconfigurations()
    },
  },
}
</script>
<style lang="scss" scoped>
.bg-home {
  // background-color: #42437d !important;
  background-color: #2b2c59 !important;
}
.drawer-list-item {
  font-weight: 600;
  color: rgb(119, 119, 119);
}
.drawer-list-item > a {
  color: rgb(119, 119, 119);
}
.nav-container {
  display: grid;
  place-items: center;
  margin-top: -5px;
}
nav {
  position: fixed;
  top: 4rem;
  z-index: 100;
  width: 100%;
  height: 70px;
  padding: 20px;
  font-family: $font-family;
  line-height: 24px;
  .logo {
    width: 200px;
    height: 46.28px;
    img {
      width: 80%;
      height: 75%;
      object-fit: contain;
    }
  }

  .drawer {
    display: none;
    .v-btn__content {
      i {
        color: $white;
      }
    }
  }
  .scrolled-drawer {
    display: none;
    .v-btn__content {
      i {
        color: rgb(102, 102, 102);
      }
    }
  }

  .nav-item-class > a,
  .nav-item-class > span,
  .submenu-nav-item,
  .submenu-nav-item-text {
    color: rgb(56, 56, 56);
  }
  .submenu-nav-item,
  .submenu-nav-item-scroll {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .submenu-nav-item-text {
    padding: 0px;
  }

  .scroll-actions {
    button,
    a {
      color: rgb(56, 56, 56) !important;
    }
  }

  ul {
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.9em;
    color: "#fff";

    li {
      position: relative;
      border-radius: 8px;

      &:hover .sub-menu-list {
        display: flex;
      }

      a,
      span {
        padding: 7px 17px;
        display: inline-block;
        cursor: pointer;
        color: $white;
        text-decoration: none;

        i {
          margin-inline-start: 7px;
        }

        &.router-link-exact-active.active {
          color: $darker-green;
        }
        &:hover {
          // background: rgba(255, 255, 255, 0.1);
          color: #5fae32;
        }

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    &.sub-menu-list {
      display: none;
      width: 160px;
      background: $hover-gray;
      color: #5f5f5f !important;
      flex-direction: column;
      position: absolute;
      border-radius: 0px 8px 8px 8px;

      li {
        &:hover {
          background: #d5d6d6;
          border-radius: 8px;

          &:first-of-type {
            border-radius: 0 8px 8px;
          }

          &:last-of-type {
            border-radius: 0 0 8px 8px;
          }
        }

        a {
          display: block;
          color: $title-2;
        }
      }
    }
  }

  .actions {
    font-weight: 500;
    font-size: 1em;
    gap: 8px;
    display: flex;

    a,
    button {
      padding: 8px;
      border-radius: 8px;
      color: $white;
      text-decoration: none;

      &.lang-btn {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      &.user-menu {
        &:hover {
          color: $green;
        }

        &:hover .user-sub-menu {
          display: flex;
        }

        .user-sub-menu {
          display: none;
          right: 0;
          background: $hover-gray;
          flex-direction: column;
          position: absolute;
          border-radius: 5px;
          li {
            text-align: start;
            width: 150px;
            border-radius: 0px;
            a {
              all: unset;
              color: $title-2;
              display: inline-block;
              padding: 10px;
            }
            &:hover {
              background: #d5d6d6;
              a {
                color: #5fae32;
              }
            }
            a {
              display: block;
            }
          }
        }
      }

      &.lang-btn,
      &.login-btn,
      &.logout-btn {
        margin: 0;

        &:hover {
          color: #5fae32;
        }
      }

      &.register-btn {
        border: transparent;
        color: $white !important;
        background: $darker-green;
        height: auto;
        font-weight: 600;
        font-size: 13px;
        border-radius: 9999px;
        box-shadow: none;
        text-transform: none;
        &:hover {
          background: $hover-green;
        }
      }

      &.profile-btn {
        color: $darker-green;
        border: 1px solid $darker-green;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
          background: #eee;
        }
      }
    }

    button.login-btn.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
      height: auto;
      background: none;
      box-shadow: none;
    }
  }

  .header--nav-dropdown-link {
    &:hover {
      color: #7caa2d;
    }
  }

  .dropdown-menu {
    a {
      border: none;
      color: #1976d2;

      &:hover {
        color: #7caa2d;
      }
    }
  }

  .dropdown {
    ul {
      all: unset;
    }
  }
}

@media all and (min-width: 768px) and (max-width: 1200px) {
  nav {
    padding: 28px;
    line-height: 24px;
    font-size: 12px;

    .logo {
      width: 108px;
      height: 26.21px;
    }
    .drawer {
      display: initial;
    }

    a {
      margin-inline-end: auto;

      img {
        width: 108px;
        height: 26px;
      }
    }

    ul {
      display: none;
      font-weight: 400;
      font-size: 1em;

      li {
        a,
        span {
          padding: 12px 16px;
        }
      }
    }

    .actions {
      button {
        display: none;
      }

      a:not(.logout-btn) {
        display: none;
      }
    }
  }

  .v-navigation-drawer {
    padding: 35px 20px;

    .v-list {
      padding: 0;

      .v-expansion-panels {
        padding: 0 8px;

        .v-expansion-panel {
          .v-expansion-panel-header {
            padding: 0;
          }

          &.v-expansion-panel--active {
            .v-expansion-panel-header {
              font-size: 16px;
              min-height: auto;
              padding: 16px 0;
            }

            button {
              padding: 0;
            }

            .v-expansion-panel-content {
              .v-expansion-panel-content__wrap {
                padding: 0 24px;
              }
            }
          }
        }
      }
    }

    a {
      text-decoration: none;
      font-family: $font-family;
      line-height: 24px;
      color: $black;
      margin-inline-start: 0;
      font-size: 16px;

      &.active {
        color: $darker-green;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

@media all and (max-width: 768px) {
  nav {
    padding: 20px 16px;

    .drawer {
      display: block;
    }

    a {
      margin-inline-end: auto;

      img {
        width: 105px;
        height: 24px;
      }
    }

    ul {
      display: none;
    }

    .actions {
      button {
        display: none;
      }

      a:not(.logout-btn) {
        display: none;
      }
    }
  }

  .v-navigation-drawer {
    padding: 35px 20px;

    .v-list {
      padding: 0;

      .v-expansion-panels {
        padding: 0 8px;

        .v-expansion-panel {
          .v-expansion-panel-header {
            font-size: 16px;
            padding: 0;
          }

          &.v-expansion-panel--active {
            .v-expansion-panel-header {
              min-height: auto;
              padding: 16px 0;
            }

            button {
              padding: 0;
            }

            .v-expansion-panel-content {
              .v-expansion-panel-content__wrap {
                padding: 0 24px;
              }
            }
          }
        }
      }
    }

    a {
      text-decoration: none;
      font-family: $font-family;
      line-height: 24px;
      color: $black;
      margin-inline-start: 0;

      &.active {
        color: $darker-green;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
</style>
