<template>
  <div class="institute-admin-layout">
    <v-progress-linear
      v-if="isRouteLoading"
      indeterminate
      color="cyan"
      style="z-index: 2000; top: -97px"
    ></v-progress-linear>
    <Header></Header>
    <transition name="slide" mode="out-in">
      <slot></slot>
    </transition>
    <!-- Start Footer-->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/Header.vue"
// import Footer from '@/components/Footer.vue';
import GlobalMixin from "@/GlobalMixin.js"
export default {
  name: "institute_admin_layout",
  mixins: [GlobalMixin],
  components: {
    Header,
    // Footer
  },
  computed: {
    isRouteLoading() {
      return this.$store.getters.GET_IS_ROUTE_LOADING
    },
  },
}
</script>
<style lang="scss">
:root {
  --auth-second-color: #0180d8;
  --auth-second-color-hover: #005c9e;
}
</style>
