<template>
  <a
    id="logout-btn"
    @click="
      logout(
        $store.getters.StateUser.user.user_type.includes('institute_admin') ||
          $store.getters.StateUser.user.user_type.includes('operator')
      )
    "
    >{{ $t("header.logout") }}</a
  >
</template>
<script>
import GlobalMixin from "@/GlobalMixin.js"

export default {
  name: "Logout",
  mixins: [GlobalMixin],
}
</script>
