<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
    v-model="opened"
  >
    <v-card>
      <v-card-title>
        <v-icon
          v-on:click="leaveKidsForm"
          v-if="registerData.register_type === 'kid'"
        >
          mdi-arrow-left
        </v-icon>
        <span v-else
          >{{ $t("welcomeTo") }}
          {{
            configs && configs.institute_name
              ? configs.institute_name.config_value
              : ""
          }}</span
        >
        <v-spacer></v-spacer>
        <v-icon v-on:click="closeModal"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text>
        <v-tabs
          v-model="tab"
          v-if="registerData.register_type !== 'kid' && !kidOnly"
        >
          <v-tab id="tab-login" key="tab-login">
            {{ $t("Login") }}
          </v-tab>
          <v-tab id="tab-register" key="tab-register">
            {{ $t("Register") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="tab-login">
            <div v-if="!loginData.user_type" class="login-buttons mt-2 w-100">
              <v-btn
                id="student-login-btn"
                class="student-btn w-100 mb-3"
                v-on:click="showLoginForm('student')"
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.2"
                    d="M4.23438 10.4668V15.3652C4.23408 15.5202 4.2842 15.6711 4.37718 15.7952C4.98243 16.6006 7.53587 19.547 12.5 19.547C17.4641 19.547 20.0176 16.6006 20.6228 15.7952C20.7158 15.6711 20.7659 15.5202 20.7656 15.3652V10.4668L12.5 14.8751L4.23438 10.4668Z"
                    fill="#061C3D"
                  />
                  <path
                    d="M1.71875 9.125L12.5 3.375L23.2812 9.125L12.5 14.875L1.71875 9.125Z"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.8906 22.0625V12L12.5 9.125"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.7656 10.4668V15.3652C20.7659 15.5202 20.7158 15.6711 20.6228 15.7952C20.0176 16.6006 17.4641 19.547 12.5 19.547C7.53587 19.547 4.98243 16.6006 4.37718 15.7952C4.2842 15.6711 4.23408 15.5202 4.23438 15.3652V10.4668"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="ms-2">{{ $t("login as student") }}</span>
              </v-btn>
              <v-btn
                id="tutor-login-btn"
                class="tutor-btn w-100 mb-3"
                v-on:click="showLoginForm('tutor')"
                v-if="!reservationMode"
              >
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.34375 16.3125C10.9316 16.3125 12.2188 15.0253 12.2188 13.4375C12.2188 .8497 1110.9316 10.5625 9.34375 10.5625C7.75593 10.5625 6.46875 11.8497 6.46875 13.4375C6.46875 15.0253 7.75593 16.3125 9.34375 16.3125Z"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    opacity="0.2"
                    d="M6.46875 13.4375C6.46875 12.8689 6.63737 12.313 6.95328 11.8402C7.26918 11.3674 7.7182 10.9989 8.24353 10.7813C8.76887 10.5637 9.34694 10.5068 9.90463 10.6177C10.4623 10.7287 10.9746 11.0025 11.3767 11.4046C11.7788 11.8066 12.0526 12.3189 12.1635 12.8766C12.2744 13.4343 12.2175 14.0124 11.9999 14.5377C11.7823 15.0631 11.4138 15.5121 10.941 15.828C10.4682 16.1439 9.91237 16.3125 9.34375 16.3125H17.25V7.6875H5.75V13.4375H6.46875Z"
                    fill="#061C3D"
                  />
                  <path
                    d="M4.79673 19.1874C5.20507 18.3271 5.84907 17.6004 6.65393 17.0915C7.4588 16.5826 8.39151 16.3125 9.34375 16.3125C10.296 16.3125 11.2287 16.5826 12.0336 17.0915C12.8384 17.6004 13.4824 18.3271 13.8908 19.1874L19.4062 19.1875C19.5006 19.1875 19.5941 19.1689 19.6813 19.1328C19.7685 19.0967 19.8477 19.0437 19.9145 18.977C19.9812 18.9102 20.0342 18.831 20.0703 18.7438C20.1064 18.6566 20.125 18.5631 20.125 18.4688V5.53125C20.125 5.34063 20.0493 5.15781 19.9145 5.02302C19.7797 4.88823 19.5969 4.8125 19.4062 4.8125H3.59375C3.40313 4.8125 3.22031 4.88823 3.08552 5.02302C2.95073 5.15781 2.875 5.34063 2.875 5.53125V18.4688C2.875 18.5632 2.89359 18.6567 2.92971 18.7439C2.96583 18.8311 3.01878 18.9103 3.08552 18.977C3.15226 19.0438 3.2315 19.0967 3.3187 19.1328C3.4059 19.1689 3.49937 19.1875 3.59375 19.1875L4.79673 19.1874Z"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.8125 16.3125H17.25V7.6875H5.75V9.125"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="ms-2">{{ $t("login as tutor") }}</span>
              </v-btn>
              <v-btn
                id="login-passcode-btn"
                class="login-passcode-btn w-100"
                v-on:click="showLoginForm('kids')"
              >
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.2"
                    d="M8.3704 11.5361C7.81634 10.1541 7.75643 8.62314 8.20082 7.20207C8.64521 5.781 9.56662 4.55694 10.8093 3.73681C12.052 2.91668 13.5398 2.55077 15.0211 2.70093C16.5025 2.8511 17.8865 3.50813 18.9394 4.56097C19.9922 5.6138 20.6492 6.99787 20.7994 8.47921C20.9495 9.96055 20.5836 11.4483 19.7635 12.691C18.9434 13.9337 17.7193 14.8551 16.2982 15.2995C14.8772 15.7439 13.3462 15.684 11.9642 15.1299L11.9643 15.1298L10.7812 16.3128H8.625V18.4691H6.46875V20.6253H2.875V17.0316L8.37056 11.536L8.3704 11.5361Z"
                    fill="#061C3D"
                  />
                  <path
                    d="M8.3704 11.5361C7.81634 10.1541 7.75643 8.62314 8.20082 7.20207C8.64521 5.781 9.56662 4.55694 10.8093 3.73681C12.052 2.91668 13.5398 2.55077 15.0211 2.70093C16.5025 2.8511 17.8865 3.50813 18.9394 4.56097C19.9922 5.6138 20.6492 6.99787 20.7994 8.47921C20.9495 9.96055 20.5836 11.4483 19.7635 12.691C18.9434 13.9337 17.7193 14.8551 16.2982 15.2995C14.8772 15.7439 13.3462 15.684 11.9642 15.1299L11.9643 15.1298L10.7812 16.3128H8.625V18.4691H6.46875V20.6253H2.875V17.0316L8.37056 11.536L8.3704 11.5361Z"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    opacity="0.5"
                    d="M16.1719 7.6875C16.3704 7.6875 16.5312 7.5266 16.5312 7.32812C16.5312 7.12965 16.3704 6.96875 16.1719 6.96875C15.9734 6.96875 15.8125 7.12965 15.8125 7.32812C15.8125 7.5266 15.9734 7.6875 16.1719 7.6875Z"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.1719 8.40625C16.7673 8.40625 17.25 7.92356 17.25 7.32812C17.25 6.73269 16.7673 6.25 16.1719 6.25C15.5764 6.25 15.0938 6.73269 15.0938 7.32812C15.0938 7.92356 15.5764 8.40625 16.1719 8.40625Z"
                    fill="#061C3D"
                  />
                </svg>
                <span class="ms-2">{{ $t("loginWithPasscode") }}</span>
              </v-btn>
            </div>
            <div v-else class="login-form mt-5 w-100 mt-2">
              <v-form v-on:submit.stop.prevent="login">
                <template
                  v-if="
                    loginData.user_type === 'tutor' ||
                    loginData.user_type === 'student'
                  "
                >
                  <v-text-field
                    id="email-input"
                    v-model="loginData.username"
                    v-bind:label="$t('Email')"
                    clearable
                    outlined
                  >
                  </v-text-field>
                  <v-text-field
                    id="password-input"
                    v-model="loginData.password"
                    v-bind:label="$t('Password')"
                    clearable
                    outlined
                    v-bind:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    v-bind:type="showPass ? 'text' : 'password'"
                    v-on:click:append="showPass = !showPass"
                  >
                  </v-text-field>
                  <div class="d-flex justify-content-between">
                    <v-switch
                      id="remember-me-switch"
                      v-model="loginData.remember"
                      v-bind:label="$t('Remember me')"
                      class="mt-0"
                    >
                    </v-switch>
                    <span
                      id="forget__password__link"
                      class="forget__password__link"
                      @click="openForgetPasswordView"
                      >{{ $t("forget password") }}</span
                    >
                  </div>
                </template>
                <template v-else>
                  <v-text-field
                    id="passcode-input"
                    v-model="loginData.username"
                    v-bind:label="$t('Passcode')"
                    outlined
                    clearable
                  >
                  </v-text-field>
                  <v-switch
                    id="remember-me-switch"
                    v-model="loginData.remember"
                    v-bind:label="$t('Remember me')"
                    class="mt-0"
                  >
                  </v-switch>
                </template>
                <v-btn
                  id="login-complete-btn"
                  type="submit"
                  class="w-100 login-btn"
                  >{{ $t("Login") }}</v-btn
                >
              </v-form>
              <template v-if="loginData.user_type === 'student'">
                <v-row class="mt-5 position-relative">
                  <v-col cols="12">
                    <v-divider color="grey"></v-divider>
                    <span
                      class="text-uppercase position-absolute top-50 start-50 translate-middle bg-white"
                    >
                      {{ $t("or") }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    {{ $t("loginWithSocial") }}
                  </v-col>
                </v-row>
                <v-row class="social-buttons">
                  <GoogleLogin></GoogleLogin>
                  <FacebookLogin></FacebookLogin>
                  <LinkedinLogin></LinkedinLogin>
                </v-row>
              </template>
            </div>
          </v-tab-item>
          <v-tab-item key="tab-register">
            <div
              v-if="!registerData.register_type && !kidOnly"
              class="register-buttons mt-2 w-100"
            >
              <v-btn
                id="student-register-btn"
                class="student-btn w-100 mb-3"
                v-on:click="showRegisterForm('student')"
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.2"
                    d="M4.23438 10.4668V15.3652C4.23408 15.5202 4.2842 15.6711 4.37718 15.7952C4.98243 16.6006 7.53587 19.547 12.5 19.547C17.4641 19.547 20.0176 16.6006 20.6228 15.7952C20.7158 15.6711 20.7659 15.5202 20.7656 15.3652V10.4668L12.5 14.8751L4.23438 10.4668Z"
                    fill="#061C3D"
                  />
                  <path
                    d="M1.71875 9.125L12.5 3.375L23.2812 9.125L12.5 14.875L1.71875 9.125Z"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.8906 22.0625V12L12.5 9.125"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.7656 10.4668V15.3652C20.7659 15.5202 20.7158 15.6711 20.6228 15.7952C20.0176 16.6006 17.4641 19.547 12.5 19.547C7.53587 19.547 4.98243 16.6006 4.37718 15.7952C4.2842 15.6711 4.23408 15.5202 4.23438 15.3652V10.4668"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="ms-2">{{ $t("registerAsStudent") }} </span>
              </v-btn>
              <v-btn
                id="tutor-register-btn"
                class="tutor-btn w-100 mb-3"
                v-on:click="showRegisterForm('tutor')"
                v-if="!reservationMode"
              >
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.34375 16.3125C10.9316 16.3125 12.2188 15.0253 12.2188 13.4375C12.2188 .8497 1110.9316 10.5625 9.34375 10.5625C7.75593 10.5625 6.46875 11.8497 6.46875 13.4375C6.46875 15.0253 7.75593 16.3125 9.34375 16.3125Z"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    opacity="0.2"
                    d="M6.46875 13.4375C6.46875 12.8689 6.63737 12.313 6.95328 11.8402C7.26918 11.3674 7.7182 10.9989 8.24353 10.7813C8.76887 10.5637 9.34694 10.5068 9.90463 10.6177C10.4623 10.7287 10.9746 11.0025 11.3767 11.4046C11.7788 11.8066 12.0526 12.3189 12.1635 12.8766C12.2744 13.4343 12.2175 14.0124 11.9999 14.5377C11.7823 15.0631 11.4138 15.5121 10.941 15.828C10.4682 16.1439 9.91237 16.3125 9.34375 16.3125H17.25V7.6875H5.75V13.4375H6.46875Z"
                    fill="#061C3D"
                  />
                  <path
                    d="M4.79673 19.1874C5.20507 18.3271 5.84907 17.6004 6.65393 17.0915C7.4588 16.5826 8.39151 16.3125 9.34375 16.3125C10.296 16.3125 11.2287 16.5826 12.0336 17.0915C12.8384 17.6004 13.4824 18.3271 13.8908 19.1874L19.4062 19.1875C19.5006 19.1875 19.5941 19.1689 19.6813 19.1328C19.7685 19.0967 19.8477 19.0437 19.9145 18.977C19.9812 18.9102 20.0342 18.831 20.0703 18.7438C20.1064 18.6566 20.125 18.5631 20.125 18.4688V5.53125C20.125 5.34063 20.0493 5.15781 19.9145 5.02302C19.7797 4.88823 19.5969 4.8125 19.4062 4.8125H3.59375C3.40313 4.8125 3.22031 4.88823 3.08552 5.02302C2.95073 5.15781 2.875 5.34063 2.875 5.53125V18.4688C2.875 18.5632 2.89359 18.6567 2.92971 18.7439C2.96583 18.8311 3.01878 18.9103 3.08552 18.977C3.15226 19.0438 3.2315 19.0967 3.3187 19.1328C3.4059 19.1689 3.49937 19.1875 3.59375 19.1875L4.79673 19.1874Z"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.8125 16.3125H17.25V7.6875H5.75V9.125"
                    stroke="#061C3D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="ms-2">{{ $t("registerAsTutor") }} </span>
              </v-btn>
            </div>
            <div v-else class="register-form mt-5 w-100 mt-2">
              <v-form v-on:submit.prevent.stop="register">
                <template
                  v-if="
                    (registerData.register_type === 'tutor' ||
                      registerData.register_type === 'student') &&
                    !kidOnly
                  "
                >
                  <v-text-field
                    id="first-name"
                    v-model="registerData.first_name"
                    v-bind:label="$t('First Name')"
                    clearable
                    outlined
                  >
                  </v-text-field>
                  <v-text-field
                    id="last-name"
                    v-model="registerData.last_name"
                    v-bind:label="$t('Last Name')"
                    clearable
                    outlined
                  >
                  </v-text-field>
                  <v-text-field
                    id="email"
                    v-model="registerData.email"
                    v-bind:label="$t('Email')"
                    clearable
                    outlined
                  >
                  </v-text-field>
                  <vue-tel-input-vuetify
                    id="mobile"
                    v-model="registerData.phone_number"
                    v-bind:label="$t('Mobile')"
                    clearable
                    outlined
                    v-on:input="changePhoneNumber"
                    mode="international"
                    >*
                  </vue-tel-input-vuetify>
                  <v-text-field
                    id="password"
                    v-model="registerData.password"
                    v-bind:label="$t('Password')"
                    clearable
                    outlined
                    v-bind:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    v-bind:type="showPass ? 'text' : 'password'"
                    v-on:click:append="showPass = !showPass"
                  >
                  </v-text-field>
                  <v-text-field
                    id="confirm-password"
                    v-model="registerData.confirm_password"
                    v-bind:label="$t('Confirm Password')"
                    clearable
                    outlined
                    v-bind:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    v-bind:type="showPass ? 'text' : 'password'"
                    v-on:click:append="showPass = !showPass"
                  >
                  </v-text-field>
                  <v-select
                    id="gender"
                    v-model="registerData.gender"
                    :label="$t('gender')"
                    :items="getAllGenders()"
                    outlined
                    clearable
                  >
                  </v-select>
                  <v-dialog
                    ref="birthDateDialog"
                    v-model="birthDateModal"
                    presistent
                    width="290px"
                    v-bind:return-value.sync="registerData.date_of_birth"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        id="date-of-birth"
                        v-model="registerData.date_of_birth"
                        v-bind:label="$t('date_of_birth')"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        clearable
                        readonly
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="registerData.date_of_birth">
                      <v-spacer></v-spacer>
                      <v-btn text v-on:click="birthDateModal = false">
                        {{ $t("Cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        v-on:click="
                          $refs.birthDateDialog.save(registerData.date_of_birth)
                        "
                      >
                        {{ $t("ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <v-checkbox
                    id="is-terms-and-conditions-accepted"
                    v-model="registerData.is_terms_and_conditions_accepted"
                  >
                    <template v-slot:label>
                      {{ $t("terms.consent") }}
                      <a
                        v-on:click.prevent="openTermsModal"
                        href="#"
                        class="mx-1"
                      >
                        {{ $t("terms.terms") }}
                      </a>
                      {{ $t("terms.and") }}
                      <a
                        v-on:click.prevent="openPrivacyModal"
                        href="#"
                        class="ms-1"
                      >
                        {{ $t("terms.privacy") }}</a
                      >
                    </template>
                  </v-checkbox>
                  <v-checkbox
                    id="is-marketing-emails-allowed"
                    v-model="registerData.is_marketing_emails_allowed"
                    v-bind:label="$t('terms.subscribe')"
                  >
                  </v-checkbox>
                  <v-card
                    id="kid-card"
                    v-for="(kid, i) in registerData.kids"
                    :key="i"
                    outlined
                    v-on:click="editKid(i)"
                    class="mb-3"
                  >
                    <v-card-text class="d-flex gap-5">
                      <v-icon
                        id="remove-kid-icon"
                        v-on:click.stop.prevent="removeKid(i)"
                        class="position-absolute end-0 top-25 me-5"
                      >
                        mdi-close
                      </v-icon>
                      <v-icon x-large v-if="kid.gender === 'Female'">
                        mdi-face-woman
                      </v-icon>
                      <v-icon x-large v-else> mdi-face-man </v-icon>
                      <div>
                        <h3>{{ kid.first_name }}</h3>
                        <p>{{ kid.date_of_birth }}</p>
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
                <v-btn
                  id="add-kid-btn"
                  v-on:click="addKid"
                  outlined
                  class="w-100 mb-3 text-capitalize"
                  v-if="registerData.register_type === 'student' && !kidOnly"
                >
                  {{
                    registerData.kids && registerData.kids.length
                      ? $t("addAnotherKid")
                      : $t("continueWithKids")
                  }}
                </v-btn>
                <v-btn
                  id="register-complete-btn"
                  v-if="registerData.register_type !== 'kid' && !kidOnly"
                  type="submit"
                  class="w-100 login-btn"
                  >{{ $t("Register") }}</v-btn
                >
              </v-form>
              <v-form
                v-on:submit.prevent="confirmKid"
                v-if="registerData.register_type === 'kid' || kidOnly"
                ref="kidForm"
                v-model="kidFormValid"
              >
                <v-text-field
                  id="kid-first-name"
                  v-model="newKid.first_name"
                  v-bind:label="$t('First Name')"
                  outlined
                  clearable
                  v-bind:rules="kidFormRules.firstName"
                >
                </v-text-field>
                <v-text-field
                  id="kid-last-name"
                  v-model="newKid.last_name"
                  v-bind:label="$t('Last Name')"
                  outlined
                  clearable
                  v-bind:rules="kidFormRules.lastName"
                >
                </v-text-field>
                <v-select
                  id="kid-gender"
                  v-model="newKid.gender"
                  v-bind:label="$t('gender')"
                  outlined
                  clearable
                  :items="getAllGenders()"
                  :rules="kidFormRules.gender"
                >
                </v-select>
                <v-dialog
                  ref="kidBirthDateDialog"
                  v-model="kidBirthDateModal"
                  presistent
                  width="290px"
                  v-bind:return-value.sync="newKid.date_of_birth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="kid-date-of-birth"
                      v-model="newKid.date_of_birth"
                      v-bind:label="$t('date_of_birth')"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      clearable
                      v-bind:rules="kidFormRules.birthDate"
                      readonly
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="newKid.date_of_birth">
                    <v-spacer></v-spacer>
                    <v-btn v-on:click="kidBirthDateModal = false">
                      {{ $t("Cancel") }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      v-on:click="
                        $refs.kidBirthDateDialog.save(newKid.date_of_birth)
                      "
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-btn id="add-kid" type="submit" class="w-100">
                  {{ $t("addKid") }}
                </v-btn>
              </v-form>
              <template v-if="registerData.register_type === 'student'">
                <v-row class="mt-5 position-relative">
                  <v-col cols="12">
                    <v-divider color="grey"></v-divider>
                    <span
                      class="text-uppercase position-absolute top-50 start-50 translate-middle bg-white"
                    >
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    {{ $t("registerWithSocial") }}
                  </v-col>
                </v-row>
                <v-row class="social-buttons">
                  <GoogleLogin></GoogleLogin>
                  <FacebookLogin></FacebookLogin>
                  <LinkedinLogin></LinkedinLogin>
                </v-row>
              </template>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <Modal
      v-bind:isOpened="termsModal"
      v-on:closeModal="termsModal = false"
      v-bind:maxWidth="1000"
      v-bind:headerTitle="$t('footer.termsAndConditions')"
      hideDefaultFooter
      v-bind:minHeight="700"
      presistent
    >
      <template #body>
        <iframe v-bind:src="termsLink" class="w-100 h-100"></iframe>
      </template>
    </Modal>
    <Modal
      v-bind:isOpened="privacyModal"
      v-on:closeModal="privacyModal = false"
      v-bind:maxWidth="1000"
      v-bind:headerTitle="$t('footer.privacyPolicy')"
      hideDefaultFooter
      v-bind:minHeight="700"
      presistent
    >
      <template #body>
        <iframe v-bind:src="privacyLink" class="w-100 h-100"></iframe>
      </template>
    </Modal>
  </v-dialog>
</template>
<script>
import GlobalMixin from "@/GlobalMixin.js"
import path from "path"
import Modal from "./Modal.vue"
import { mapActions } from "vuex"
import FacebookLogin from "@/components/FacebookLogin.vue"
import GoogleLogin from "@/components/GoogleLogin.vue"
import LinkedinLogin from "@/components/LinkedinLogin.vue"
import moment from "moment"

export default {
  name: "login-modal",
  mixins: [GlobalMixin],
  data: () => ({
    configs: null,
    tab: null,
    opened: false,
    registerMode: null,
    loginData: {
      user_type: null,
    },
    registerData: {
      register_type: null,
    },
    showPass: false,
    birthDateModal: false,
    termsLink: null,
    privacyLink: null,
    termsModal: false,
    privacyModal: false,
    kidFormValid: true,
    kidBirthDateModal: false,
    editKidId: null,
    newKid: {},
    kidOnly: false,
    reservationMode: false,
  }),
  mounted() {
    var self = this
    self.configs = JSON.parse(localStorage.getItem("configs"))
    window.addEventListener("localstorageChange", event => {
      self.configs = JSON.parse(event.detail.storage.configs)
    })
    window.addEventListener("loginModalChange", event => {
      self.opened = event.detail.opened
      self.tab = event.detail.tab
      self.kidOnly = event.detail.kidOnly
      if (self.opened) {
        sessionStorage.setItem("landing-contact", true)
      }
    })
  },
  methods: {
    openTermsModal() {
      this.termsModal = true
      if (!this.termsLink) this.getUrls()
    },
    openPrivacyModal() {
      this.privacyModal = true
      if (!this.privacyLink) this.getUrls()
    },
    getAllGenders() {
      return [
        {
          text: this.$t("Male"),
          value: "Male",
        },
        {
          text: this.$t("Female"),
          value: "Female",
        },
      ]
    },
    ...mapActions(["StoreUser"]),
    showLoginForm(mode) {
      this.loginData.user_type = mode
    },
    showRegisterForm(mode) {
      this.registerData.register_type = mode
    },
    closeModal() {
      window.dispatchEvent(
        new CustomEvent("loginModalChange", {
          detail: {
            opened: false,
            tab: 0,
          },
        })
      )
      this.loginData = {
        user_type: null,
      }
      this.registerData = {
        register_type: null,
      }
    },
    getUrls() {
      this.axios
        .get(`${process.env.VUE_APP_APIEndPoint}institutes/terms/`)
        .then(res => {
          this.termsLink = res.data.data.terms_and_conditions_url
          this.privacyLink = res.data.data.privacy_policy_url
        })
    },
    login() {
      this.ShowLoading()
      this.axios
        .post(
          `${process.env.VUE_APP_APIEndPoint}auth/token/`,
          this.loginData,
          this.headers
        )
        .then(res => {
          if (res.data.success) {
            this.notify(this.$t("login success"), "success")
            this.StoreUser(res.data)
            const userType = res.data.user.user_type
            if (
              this.$route.name === "ProgramsList" ||
              this.$route.name === "KidsCoursesList" ||
              this.$route.name === "Courses" ||
              this.$route.name === "Webinars"
            )
              return this.$router.go()
            if (userType === "student" || userType === "kids")
              this.$router.push({ name: "BookedClasses" })
            else if (userType === "tutor")
              this.$router.push({ name: "CurrentClasses" })
            else this.$router.push({ name: "ApprovedTutors" })
          } else {
            this.notify(this.$t(res.data.Messages[0].Message), "error")
          }
        })
        .catch(error => {
          this.displayError(error)
        })
        .finally(() => {
          this.HideLoading()
        })
    },
    register() {
      this.ShowLoading()
      let registrationType = "signup"
      if (
        this.$route.name === "Courses" ||
        this.$route.name === "KidsCoursesList" ||
        this.$route.name === "Webinars"
      )
        registrationType = "signup_with_signin"
      if (this.registerData.phone_number) {
        this.registerData.phone_number =
          this.registerData.phone_number.replaceAll(" ", "")
      }
      this.axios
        .post(
          `${process.env.VUE_APP_APIEndPoint}auth/${registrationType}/user/`,
          this.registerData,
          this.headers
        )
        .then(res => {
          if (registrationType === "signup_with_signin")
            this.StoreUser(res.data)
          if (res.data.success) {
            this.notify(this.successmsg, "success")
            this.closeModal()
          } else {
            this.notify(this.$t(res.data.Messages[0].Message), "error")
          }
        })
        .catch(error => {
          this.displayError(error)
        })
        .finally(() => {
          this.HideLoading()
        })
    },
    changePhoneNumber(val, { number, valid, country }) {
      this.registerData.phone_number = number.e164
    },
    addKid() {
      this.registerData.register_type = "kid"
      this.newKid = {}
    },
    leaveKidsForm() {
      if (this.kidOnly) {
        this.closeModal()
      } else {
        this.registerData.register_type = "student"
      }
    },
    confirmKid() {
      this.$refs.kidForm.validate()
      if (this.kidFormValid) {
        this.newKid.register_type = "kid"
        if (this.kidOnly) {
          this.axios
            .post(
              `${process.env.VUE_APP_APIEndPoint}auth/kid/add/`,
              this.newKid,
              this.headers
            )
            .then(res => {
              this.$router.go()
            })
            .catch(error => this.formatErrors(error))
        } else {
          if (this.editKidId) {
            this.registerData.kids[this.editKidId] = this.newKid
          } else {
            if (!this.registerData.kids || !this.registerData.kids.length) {
              this.registerData.kids = []
            }
            this.registerData.kids.push(this.newKid)
          }
          this.newKid = {}
          this.registerData.register_type = "student"
        }
      }
    },
    editKid(i) {
      this.editKidId = i
      this.newKid = this.registerData.kids[i]
      this.registerData.register_type = "kid"
    },
    removeKid(i) {
      this.registerData.kids.splice(i, 1)
    },
    /**
     * Open foregt password view and also close login modal
     * @returns void
     */
    openForgetPasswordView() {
      this.closeModal()
      this.$router.push({ name: "ForgetPassword" })
    },
  },
  watch: {
    opened: {
      handler() {
        this.reservationMode =
          this.$route.name === "KidsCoursesList" ||
          this.$route.fullPath.includes("reservation")
        this.loginData.user_type = null
        this.registerData.register_type = null
      },
    },
  },
  computed: {
    kidFormRules() {
      return {
        firstName: [v => !!v || this.$t("required")],
        lastName: [v => !!v || this.$t("required")],
        gender: [v => !!v || this.$t("required")],
        birthDate: [
          v => !!v || this.$t("required"),
          v =>
            moment(moment.now()).diff(moment(v, "YYYY-MM-DD"), "years") > 3 ||
            this.$t("kidAgeError"),
        ],
      }
    },
  },
  components: {
    path,
    Modal,
    FacebookLogin,
    GoogleLogin,
    LinkedinLogin,
  },
}
</script>
<style scoped lang="scss">
::v-deep .v-tabs {
  font-family: "Poppins";

  .v-tabs-slider-wrapper {
    height: 0 !important;
  }

  .v-tabs-bar {
    height: 65px;
  }

  .v-slide-group__content.v-tabs-bar__content {
    background: $light-green;
    border-radius: 8px;
    margin: 0 auto;
    color: black;
    padding: 10px 28px;
    min-height: 60px;

    .v-tab {
      width: 50%;
      min-height: 40px;
      text-transform: none;
    }

    .v-tab--active {
      background: $green;
      border-radius: 8px;
    }
  }
}

::v-deep .login-buttons,
::v-deep .register-buttons {
  font-family: "Poppins";

  button {
    box-shadow: none;

    .v-btn__content {
      justify-content: start;
      text-transform: none;
    }

    &.tutor-btn {
      background-color: $light-yellow;
    }

    &.student-btn {
      background-color: $light-purple;
    }

    &.login-passcode-btn {
      background-color: $baby-blue;
    }
  }
}

.social-buttons {
  ::v-deep button {
    font-family: "Poppins";
    padding: 13px !important;
    box-shadow: none;
    border: 1px solid #dadce0 !important;
    font-family: "Google Sans", arial, sans-serif;

    .v-btn__content {
      text-transform: none;

      svg {
        position: absolute;
        left: 0;
      }
    }
  }
}

::v-deep .login-form,
::v-deep .register-form {
  .theme--light.v-btn.v-btn--has-bg.login-btn {
    background-color: $grey;
    font-family: "Poppins";
    text-transform: none;
  }
}

.forget__password__link {
  cursor: pointer;
  text-decoration: underline;
  color: #1976d2;
}
</style>
