<template>
  <div>
    <h1 class="title">{{ $t("PAGE 404") }}</h1>
    <p class="notification is-info">
      {{ $t("Not found") }}
      <router-link class="button is-white" to="/">{{
        $t("GET BACK HOME")
      }}</router-link>
    </p>
  </div>
</template>
<script>
import GlobalMixin from "@/GlobalMixin.js"

export default {
  mixins: [GlobalMixin],
}
</script>
