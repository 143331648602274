const state = {
  selectedTutor: null,
  selectedCourses: [],
  selectedSlots: [],
  selectedProgram: null,
  paymentInfo: null,
  student: 0,
}

const getters = {
  GET_SELECTED_TUTOR_DETAILS(state) {
    return state.selectedTutor
  },

  GET_SELECTED_COURSES(state) {
    return state.selectedCourses
  },

  GET_SELECTED_PROGRAM(state) {
    return state.selectedProgram
  },

  GET_SELECTED_SLOTS(state) {
    return state.selectedSlots
  },

  GET_PAYMENT_INFO(state) {
    return state.paymentInfo
  },

  GET_STUDENT(state) {
    return state.studentId
  },
}

const mutations = {
  /**
   * Save selected tutor details to call in each view instead of calling API multiple times
   * @param payload tutorDetails object
   */
  SAVE_SELECTED_TUTOR_DETAILS(state, payload) {
    state.selectedTutor = payload
  },

  /**
   * Save ID of selected courses into state
   * @param payload number
   */
  SAVE_SELECTED_COURSE(state, payload) {
    state.selectedCourses.push(payload)
  },

  /**
   * Remove ID of course from selected courses
   * @param payload number
   */
  REMOVE_SELECTED_COURSE(state, payload) {
    const index = state.selectedCourses.indexOf(payload)
    state.selectedCourses.splice(index, 1)
  },

  /**
   * Save Program id into the state
   * @param payload number | null
   */
  SAVE_PROGRAM(state, payload) {
    state.selectedProgram = payload
  },

  /**
   * Save selected slot
   * @param payload slot object
   */
  SAVE_SELECTED_SLOT(state, payload) {
    state.selectedSlots.push({
      id: payload.id,
      start_date_time: payload.scheduled_utc_start_date_time
        ? payload.scheduled_utc_start_date_time
        : payload.start_date_time,
      end_date_time: payload.scheduled_utc_end_date_time
        ? payload.scheduled_utc_end_date_time
        : payload.end_date_time,
    })
  },

  /**
   * Remove selected slot from array
   * @param payload number | null
   */
  REMOVE_SELECTED_SLOT(state, payload) {
    if (!payload) return (state.selectedSlots = [])
    const index = state.selectedSlots.findIndex(s => s.id === payload)
    state.selectedSlots.splice(index, 1)
  },

  /**
   * Save Payment info into state
   * @param payload Payment Info Object
   */
  SAVE_PAYMENT_INFO(state, payload) {
    state.paymentInfo = payload
  },

  /**
   * Reset all selected courses, programs and time slots
   * @param payload undefined
   */
  RESET_SELECTIONS(state) {
    state.selectedTutor = null
    state.selectedCourses = []
    state.selectedSlots = []
    state.selectedProgram = null
    state.paymentInfo = null
    state.studentId = null
  },

  SAVE_STUDENT(state, payload) {
    state.studentId = payload
  },
}

const actions = {
  // Tutor details
  saveSelectedTutorDetails({ commit }, payload) {
    commit("SAVE_SELECTED_TUTOR_DETAILS", payload)
  },

  // Selected courses
  saveSelectedCourse({ commit }, payload) {
    commit("SAVE_SELECTED_COURSE", payload)
  },

  // Remove selected course
  removeSelectedCourse({ commit }, payload) {
    commit("REMOVE_SELECTED_COURSE", payload)
  },

  // Selected program
  saveProgram({ commit }, payload) {
    commit("SAVE_PROGRAM", payload)
  },

  // Selected time slots
  saveSelectedSlot({ commit }, payload) {
    commit("SAVE_SELECTED_SLOT", payload)
  },

  // Remove selected slot
  removeSelectedSlot({ commit }, payload) {
    commit("REMOVE_SELECTED_SLOT", payload)
  },

  // Save payment Info
  savePaymentInfo({ commit }, payload) {
    commit("SAVE_PAYMENT_INFO", payload)
  },

  // Reset all selections
  resetSelections({ commit }) {
    commit("RESET_SELECTIONS")
  },

  saveStudentToStore({ commit }, payload) {
    commit("SAVE_STUDENT", payload)
  },
}

export default { state, getters, mutations, actions }
