import $ from "jquery"
import { mapActions } from "vuex"
import store from "../src/store"
import moment from "moment"

export default {
  data() {
    var self = this
    return {
      genders: [
        {
          text: self.$t("Male"),
          value: "Male",
        },
        {
          text: self.$t("Female"),
          value: "Female",
        },
      ],
      studentStatuses: [
        {
          text: self.$t("studentStatuses.active"),
          value: "active",
          boolean_value: true,
        },
        {
          text: self.$t("studentStatuses.inactive"),
          value: "inactive",
          boolean_value: false,
        },
      ],
      quizStatuses: [
        {
          text: self.$t("quizStatuses.taken"),
          value: 1,
        },
        {
          text: self.$t("quizStatuses.not_taken"),
          value: 0,
        },
      ],
      homeworkStatuses: [
        {
          text: self.$t("homework.answered"),
          value: 1,
        },
        {
          text: self.$t("homework.notAnswered"),
          value: 0,
        },
      ],
      dictionary: {
        en: {
          custom: {
            password: {
              required: () =>
                self.$t("Password") + " " + self.$t("requiredFemale"),
            },
          },
          messages: {
            required: function required(fieldName) {
              return self.$t(fieldName) + " " + self.$t("required")
            },
            email: function email() {
              return "Invalid Email Format"
            },
            max: function max(fieldName, numbers) {
              return (
                self.$t(fieldName) +
                self.$t("max") +
                " " +
                numbers +
                " " +
                self.$t("numbers")
              )
            },
            min: function min(fieldName, numbers) {
              return (
                self.$t(fieldName) +
                " " +
                self.$t("min") +
                " " +
                numbers +
                " " +
                self.$t("numbers")
              )
            },
            alpha: function alpha_spaces(fieldName) {
              return self.$t(fieldName) + " " + self.$t("shouldBeCharOnly")
            },
            alpha_spaces: function alpha_spaces(fieldName) {
              return self.$t(fieldName) + " " + self.$t("shouldBeCharOnly")
            },
            //between: function between(fieldName, n1, n2) {
            //  return self.$t(fieldName) + ' ' + n1 + ' ' + n2;
            //},
            confirmed: function confirmed(fieldName) {
              return self.$t(fieldName) + " " + self.$t("NotConfirmed")
            },
            regex: function regex(fieldName) {
              return (
                self.$t(fieldName) + " " + self.$t("NotMatchPasswordPattern")
              )
            },

            between: function between(fieldName, n) {
              return (
                self.$t(fieldName) +
                " " +
                " Should be Between " +
                n[0] +
                " and " +
                n[1]
              )
            },
            numeric: function (fieldName) {
              return self.$t(fieldName) + " " + self.$t("should be Number")
            },
            verify_password: function (fieldName) {
              return self.$t(fieldName) + " " + self.$t("ComplexPassword")
            },
            phone: function (fieldName) {
              return self.$t(fieldName) + " " + self.$t("should be Number")
            },
          },
        },
        ar: {
          custom: {
            password: {
              required: () =>
                self.$t("Password") + " " + self.$t("requiredFemale"),
            },
          },
          messages: {
            required: function required(fieldName) {
              return self.$t(fieldName) + " " + self.$t("required")
            },
            email: function email() {
              return "تنسيق بريد إلكتروني غير صالح"
            },
            max: function max(fieldName, numbers) {
              return (
                self.$t(fieldName) +
                self.$t("max") +
                numbers +
                self.$t("numbers")
              )
            },
            min: function min(fieldName, numbers) {
              return (
                self.$t(fieldName) +
                " " +
                self.$t("min") +
                " " +
                numbers +
                " " +
                self.$t("numbers")
              )
            },
            alpha: function alpha_spaces(fieldName) {
              return self.$t(fieldName) + " " + self.$t("shouldBeCharOnly")
            },
            alpha_spaces: function alpha_spaces(fieldName) {
              return self.$t(fieldName) + " " + self.$t("shouldBeCharOnly")
            },
            //between: function between(fieldName, n) {
            //     return self.$t(fieldName) + self.$t('min') + n[0] + self.$t('max') + n[1];
            //},
            confirmed: function confirmed(fieldName) {
              return self.$t(fieldName) + " " + self.$t("NotConfirmed")
            },
            regex: function regex(fieldName) {
              return (
                self.$t(fieldName) + " " + self.$t("NotMatchPasswordPattern")
              )
            },
            between: function between(fieldName, n) {
              return (
                self.$t(fieldName) +
                " " +
                "يجب ان يكون بين " +
                n[0] +
                "و" +
                n[1]
              )
            },
            numeric: function (fieldName) {
              return self.$t(fieldName) + " " + self.$t("should be Number")
            },
            verify_password: function (fieldName) {
              return self.$t(fieldName) + " " + self.$t("ComplexPassword")
            },
            phone: function (fieldName) {
              return self.$t(fieldName) + " " + self.$t("should be Number")
            },
          },
        },
      },
      showValidMessage: false,
      langPathImg:
        localStorage.lang == "en"
          ? "/assets/images/ar.webp"
          : "/assets/images/en.webp",
      langeName: localStorage.lang == "en" ? "العربية" : "English",
      loader: {},
      successmsg: self.$t("savedSucess"),
      baseUrl: process.env.VUE_APP_APIEndPoint,
      queryUrl: "",
      getoneUrl: "",
      insertUrl: process.env.VUE_APP_APIEndPoint + "City/Add",
      updateUrl: "",
      deleteUrl: "",
      countries: [
        {
          id: 3,
          name: "Afghanistan",
          name_ascii: "Afghanistan",
          phone: "93",
        },
        {
          id: 15,
          name: "Aland Islands",
          name_ascii: "Aland Islands",
          phone: "358-18",
        },
        {
          id: 6,
          name: "Albania",
          name_ascii: "Albania",
          phone: "355",
        },
        {
          id: 62,
          name: "Algeria",
          name_ascii: "Algeria",
          phone: "213",
        },
        {
          id: 11,
          name: "American Samoa",
          name_ascii: "American Samoa",
          phone: "1-684",
        },
        {
          id: 1,
          name: "Andorra",
          name_ascii: "Andorra",
          phone: "376",
        },
        {
          id: 8,
          name: "Angola",
          name_ascii: "Angola",
          phone: "244",
        },
        {
          id: 5,
          name: "Anguilla",
          name_ascii: "Anguilla",
          phone: "1-264",
        },
        {
          id: 9,
          name: "Antarctica",
          name_ascii: "Antarctica",
          phone: "",
        },
        {
          id: 4,
          name: "Antigua and Barbuda",
          name_ascii: "Antigua and Barbuda",
          phone: "1-268",
        },
        {
          id: 10,
          name: "Argentina",
          name_ascii: "Argentina",
          phone: "54",
        },
        {
          id: 7,
          name: "Armenia",
          name_ascii: "Armenia",
          phone: "374",
        },
        {
          id: 14,
          name: "Aruba",
          name_ascii: "Aruba",
          phone: "297",
        },
        {
          id: 13,
          name: "Australia",
          name_ascii: "Australia",
          phone: "61",
        },
        {
          id: 12,
          name: "Austria",
          name_ascii: "Austria",
          phone: "43",
        },
        {
          id: 16,
          name: "Azerbaijan",
          name_ascii: "Azerbaijan",
          phone: "994",
        },
        {
          id: 32,
          name: "Bahamas",
          name_ascii: "Bahamas",
          phone: "1-242",
        },
        {
          id: 23,
          name: "Bahrain",
          name_ascii: "Bahrain",
          phone: "973",
        },
        {
          id: 19,
          name: "Bangladesh",
          name_ascii: "Bangladesh",
          phone: "880",
        },
        {
          id: 18,
          name: "Barbados",
          name_ascii: "Barbados",
          phone: "1-246",
        },
        {
          id: 36,
          name: "Belarus",
          name_ascii: "Belarus",
          phone: "375",
        },
        {
          id: 20,
          name: "Belgium",
          name_ascii: "Belgium",
          phone: "32",
        },
        {
          id: 37,
          name: "Belize",
          name_ascii: "Belize",
          phone: "501",
        },
        {
          id: 25,
          name: "Benin",
          name_ascii: "Benin",
          phone: "229",
        },
        {
          id: 27,
          name: "Bermuda",
          name_ascii: "Bermuda",
          phone: "1-441",
        },
        {
          id: 33,
          name: "Bhutan",
          name_ascii: "Bhutan",
          phone: "975",
        },
        {
          id: 29,
          name: "Bolivia",
          name_ascii: "Bolivia",
          phone: "591",
        },
        {
          id: 30,
          name: "Bonaire, Saint Eustatius and Saba",
          name_ascii: "Bonaire, Saint Eustatius and Saba",
          phone: "599",
        },
        {
          id: 17,
          name: "Bosnia and Herzegovina",
          name_ascii: "Bosnia and Herzegovina",
          phone: "387",
        },
        {
          id: 35,
          name: "Botswana",
          name_ascii: "Botswana",
          phone: "267",
        },
        {
          id: 34,
          name: "Bouvet Island",
          name_ascii: "Bouvet Island",
          phone: "",
        },
        {
          id: 31,
          name: "Brazil",
          name_ascii: "Brazil",
          phone: "55",
        },
        {
          id: 106,
          name: "British Indian Ocean Territory",
          name_ascii: "British Indian Ocean Territory",
          phone: "246",
        },
        {
          id: 240,
          name: "British Virgin Islands",
          name_ascii: "British Virgin Islands",
          phone: "1-284",
        },
        {
          id: 28,
          name: "Brunei",
          name_ascii: "Brunei",
          phone: "673",
        },
        {
          id: 22,
          name: "Bulgaria",
          name_ascii: "Bulgaria",
          phone: "359",
        },
        {
          id: 21,
          name: "Burkina Faso",
          name_ascii: "Burkina Faso",
          phone: "226",
        },
        {
          id: 24,
          name: "Burundi",
          name_ascii: "Burundi",
          phone: "257",
        },
        {
          id: 117,
          name: "Cambodia",
          name_ascii: "Cambodia",
          phone: "855",
        },
        {
          id: 47,
          name: "Cameroon",
          name_ascii: "Cameroon",
          phone: "237",
        },
        {
          id: 38,
          name: "Canada",
          name_ascii: "Canada",
          phone: "1",
        },
        {
          id: 52,
          name: "Cape Verde",
          name_ascii: "Cape Verde",
          phone: "238",
        },
        {
          id: 125,
          name: "Cayman Islands",
          name_ascii: "Cayman Islands",
          phone: "1-345",
        },
        {
          id: 41,
          name: "Central African Republic",
          name_ascii: "Central African Republic",
          phone: "236",
        },
        {
          id: 216,
          name: "Chad",
          name_ascii: "Chad",
          phone: "235",
        },
        {
          id: 46,
          name: "Chile",
          name_ascii: "Chile",
          phone: "56",
        },
        {
          id: 48,
          name: "China",
          name_ascii: "China",
          phone: "86",
        },
        {
          id: 54,
          name: "Christmas Island",
          name_ascii: "Christmas Island",
          phone: "61",
        },
        {
          id: 39,
          name: "Cocos Islands",
          name_ascii: "Cocos Islands",
          phone: "61",
        },
        {
          id: 49,
          name: "Colombia",
          name_ascii: "Colombia",
          phone: "57",
        },
        {
          id: 119,
          name: "Comoros",
          name_ascii: "Comoros",
          phone: "269",
        },
        {
          id: 45,
          name: "Cook Islands",
          name_ascii: "Cook Islands",
          phone: "682",
        },
        {
          id: 50,
          name: "Costa Rica",
          name_ascii: "Costa Rica",
          phone: "506",
        },
        {
          id: 98,
          name: "Croatia",
          name_ascii: "Croatia",
          phone: "385",
        },
        {
          id: 51,
          name: "Cuba",
          name_ascii: "Cuba",
          phone: "53",
        },
        {
          id: 53,
          name: "Curacao",
          name_ascii: "Curacao",
          phone: "599",
        },
        {
          id: 55,
          name: "Cyprus",
          name_ascii: "Cyprus",
          phone: "357",
        },
        {
          id: 56,
          name: "Czech Republic",
          name_ascii: "Czech Republic",
          phone: "420",
        },
        {
          id: 40,
          name: "Democratic Republic of the Congo",
          name_ascii: "Democratic Republic of the Congo",
          phone: "243",
        },
        {
          id: 59,
          name: "Denmark",
          name_ascii: "Denmark",
          phone: "45",
        },
        {
          id: 58,
          name: "Djibouti",
          name_ascii: "Djibouti",
          phone: "253",
        },
        {
          id: 60,
          name: "Dominica",
          name_ascii: "Dominica",
          phone: "1-767",
        },
        {
          id: 61,
          name: "Dominican Republic",
          name_ascii: "Dominican Republic",
          phone: "1-809 and 1-829",
        },
        {
          id: 222,
          name: "East Timor",
          name_ascii: "East Timor",
          phone: "670",
        },
        {
          id: 63,
          name: "Ecuador",
          name_ascii: "Ecuador",
          phone: "593",
        },
        {
          id: 65,
          name: "Egypt",
          name_ascii: "Egypt",
          phone: "20",
        },
        {
          id: 211,
          name: "El Salvador",
          name_ascii: "El Salvador",
          phone: "503",
        },
        {
          id: 88,
          name: "Equatorial Guinea",
          name_ascii: "Equatorial Guinea",
          phone: "240",
        },
        {
          id: 67,
          name: "Eritrea",
          name_ascii: "Eritrea",
          phone: "291",
        },
        {
          id: 64,
          name: "Estonia",
          name_ascii: "Estonia",
          phone: "372",
        },
        {
          id: 69,
          name: "Ethiopia",
          name_ascii: "Ethiopia",
          phone: "251",
        },
        {
          id: 72,
          name: "Falkland Islands",
          name_ascii: "Falkland Islands",
          phone: "500",
        },
        {
          id: 74,
          name: "Faroe Islands",
          name_ascii: "Faroe Islands",
          phone: "298",
        },
        {
          id: 71,
          name: "Fiji",
          name_ascii: "Fiji",
          phone: "679",
        },
        {
          id: 70,
          name: "Finland",
          name_ascii: "Finland",
          phone: "358",
        },
        {
          id: 75,
          name: "France",
          name_ascii: "France",
          phone: "33",
        },
        {
          id: 80,
          name: "French Guiana",
          name_ascii: "French Guiana",
          phone: "594",
        },
        {
          id: 176,
          name: "French Polynesia",
          name_ascii: "French Polynesia",
          phone: "689",
        },
        {
          id: 217,
          name: "French Southern Territories",
          name_ascii: "French Southern Territories",
          phone: "",
        },
        {
          id: 76,
          name: "Gabon",
          name_ascii: "Gabon",
          phone: "241",
        },
        {
          id: 85,
          name: "Gambia",
          name_ascii: "Gambia",
          phone: "220",
        },
        {
          id: 79,
          name: "Georgia",
          name_ascii: "Georgia",
          phone: "995",
        },
        {
          id: 57,
          name: "Germany",
          name_ascii: "Germany",
          phone: "49",
        },
        {
          id: 82,
          name: "Ghana",
          name_ascii: "Ghana",
          phone: "233",
        },
        {
          id: 83,
          name: "Gibraltar",
          name_ascii: "Gibraltar",
          phone: "350",
        },
        {
          id: 89,
          name: "Greece",
          name_ascii: "Greece",
          phone: "30",
        },
        {
          id: 84,
          name: "Greenland",
          name_ascii: "Greenland",
          phone: "299",
        },
        {
          id: 78,
          name: "Grenada",
          name_ascii: "Grenada",
          phone: "1-473",
        },
        {
          id: 87,
          name: "Guadeloupe",
          name_ascii: "Guadeloupe",
          phone: "590",
        },
        {
          id: 92,
          name: "Guam",
          name_ascii: "Guam",
          phone: "1-671",
        },
        {
          id: 91,
          name: "Guatemala",
          name_ascii: "Guatemala",
          phone: "502",
        },
        {
          id: 81,
          name: "Guernsey",
          name_ascii: "Guernsey",
          phone: "44-1481",
        },
        {
          id: 86,
          name: "Guinea",
          name_ascii: "Guinea",
          phone: "224",
        },
        {
          id: 93,
          name: "Guinea-Bissau",
          name_ascii: "Guinea-Bissau",
          phone: "245",
        },
        {
          id: 94,
          name: "Guyana",
          name_ascii: "Guyana",
          phone: "592",
        },
        {
          id: 99,
          name: "Haiti",
          name_ascii: "Haiti",
          phone: "509",
        },
        {
          id: 96,
          name: "Heard Island and McDonald Islands",
          name_ascii: "Heard Island and McDonald Islands",
          phone: "",
        },
        {
          id: 97,
          name: "Honduras",
          name_ascii: "Honduras",
          phone: "504",
        },
        {
          id: 95,
          name: "Hong Kong",
          name_ascii: "Hong Kong",
          phone: "852",
        },
        {
          id: 100,
          name: "Hungary",
          name_ascii: "Hungary",
          phone: "36",
        },
        {
          id: 109,
          name: "Iceland",
          name_ascii: "Iceland",
          phone: "354",
        },
        {
          id: 105,
          name: "India",
          name_ascii: "India",
          phone: "91",
        },
        {
          id: 101,
          name: "Indonesia",
          name_ascii: "Indonesia",
          phone: "62",
        },
        {
          id: 108,
          name: "Iran",
          name_ascii: "Iran",
          phone: "98",
        },
        {
          id: 107,
          name: "Iraq",
          name_ascii: "Iraq",
          phone: "964",
        },
        {
          id: 102,
          name: "Ireland",
          name_ascii: "Ireland",
          phone: "353",
        },
        {
          id: 104,
          name: "Isle of Man",
          name_ascii: "Isle of Man",
          phone: "44-1624",
        },
        {
          id: 103,
          name: "Israel",
          name_ascii: "Israel",
          phone: "972",
        },
        {
          id: 110,
          name: "Italy",
          name_ascii: "Italy",
          phone: "39",
        },
        {
          id: 44,
          name: "Ivory Coast",
          name_ascii: "Ivory Coast",
          phone: "225",
        },
        {
          id: 112,
          name: "Jamaica",
          name_ascii: "Jamaica",
          phone: "1-876",
        },
        {
          id: 114,
          name: "Japan",
          name_ascii: "Japan",
          phone: "81",
        },
        {
          id: 111,
          name: "Jersey",
          name_ascii: "Jersey",
          phone: "44-1534",
        },
        {
          id: 113,
          name: "Jordan",
          name_ascii: "Jordan",
          phone: "962",
        },
        {
          id: 126,
          name: "Kazakhstan",
          name_ascii: "Kazakhstan",
          phone: "7",
        },
        {
          id: 115,
          name: "Kenya",
          name_ascii: "Kenya",
          phone: "254",
        },
        {
          id: 118,
          name: "Kiribati",
          name_ascii: "Kiribati",
          phone: "686",
        },
        {
          id: 123,
          name: "Kosovo",
          name_ascii: "Kosovo",
          phone: "",
        },
        {
          id: 124,
          name: "Kuwait",
          name_ascii: "Kuwait",
          phone: "965",
        },
        {
          id: 116,
          name: "Kyrgyzstan",
          name_ascii: "Kyrgyzstan",
          phone: "996",
        },
        {
          id: 127,
          name: "Laos",
          name_ascii: "Laos",
          phone: "856",
        },
        {
          id: 136,
          name: "Latvia",
          name_ascii: "Latvia",
          phone: "371",
        },
        {
          id: 128,
          name: "Lebanon",
          name_ascii: "Lebanon",
          phone: "961",
        },
        {
          id: 133,
          name: "Lesotho",
          name_ascii: "Lesotho",
          phone: "266",
        },
        {
          id: 132,
          name: "Liberia",
          name_ascii: "Liberia",
          phone: "231",
        },
        {
          id: 137,
          name: "Libya",
          name_ascii: "Libya",
          phone: "218",
        },
        {
          id: 130,
          name: "Liechtenstein",
          name_ascii: "Liechtenstein",
          phone: "423",
        },
        {
          id: 134,
          name: "Lithuania",
          name_ascii: "Lithuania",
          phone: "370",
        },
        {
          id: 135,
          name: "Luxembourg",
          name_ascii: "Luxembourg",
          phone: "352",
        },
        {
          id: 149,
          name: "Macao",
          name_ascii: "Macao",
          phone: "853",
        },
        {
          id: 145,
          name: "Macedonia",
          name_ascii: "Macedonia",
          phone: "389",
        },
        {
          id: 143,
          name: "Madagascar",
          name_ascii: "Madagascar",
          phone: "261",
        },
        {
          id: 157,
          name: "Malawi",
          name_ascii: "Malawi",
          phone: "265",
        },
        {
          id: 159,
          name: "Malaysia",
          name_ascii: "Malaysia",
          phone: "60",
        },
        {
          id: 156,
          name: "Maldives",
          name_ascii: "Maldives",
          phone: "960",
        },
        {
          id: 146,
          name: "Mali",
          name_ascii: "Mali",
          phone: "223",
        },
        {
          id: 154,
          name: "Malta",
          name_ascii: "Malta",
          phone: "356",
        },
        {
          id: 144,
          name: "Marshall Islands",
          name_ascii: "Marshall Islands",
          phone: "692",
        },
        {
          id: 151,
          name: "Martinique",
          name_ascii: "Martinique",
          phone: "596",
        },
        {
          id: 152,
          name: "Mauritania",
          name_ascii: "Mauritania",
          phone: "222",
        },
        {
          id: 155,
          name: "Mauritius",
          name_ascii: "Mauritius",
          phone: "230",
        },
        {
          id: 247,
          name: "Mayotte",
          name_ascii: "Mayotte",
          phone: "262",
        },
        {
          id: 158,
          name: "Mexico",
          name_ascii: "Mexico",
          phone: "52",
        },
        {
          id: 73,
          name: "Micronesia",
          name_ascii: "Micronesia",
          phone: "691",
        },
        {
          id: 140,
          name: "Moldova",
          name_ascii: "Moldova",
          phone: "373",
        },
        {
          id: 139,
          name: "Monaco",
          name_ascii: "Monaco",
          phone: "377",
        },
        {
          id: 148,
          name: "Mongolia",
          name_ascii: "Mongolia",
          phone: "976",
        },
        {
          id: 141,
          name: "Montenegro",
          name_ascii: "Montenegro",
          phone: "382",
        },
        {
          id: 153,
          name: "Montserrat",
          name_ascii: "Montserrat",
          phone: "1-664",
        },
        {
          id: 138,
          name: "Morocco",
          name_ascii: "Morocco",
          phone: "212",
        },
        {
          id: 160,
          name: "Mozambique",
          name_ascii: "Mozambique",
          phone: "258",
        },
        {
          id: 147,
          name: "Myanmar",
          name_ascii: "Myanmar",
          phone: "95",
        },
        {
          id: 161,
          name: "Namibia",
          name_ascii: "Namibia",
          phone: "264",
        },
        {
          id: 170,
          name: "Nauru",
          name_ascii: "Nauru",
          phone: "674",
        },
        {
          id: 169,
          name: "Nepal",
          name_ascii: "Nepal",
          phone: "977",
        },
        {
          id: 167,
          name: "Netherlands",
          name_ascii: "Netherlands",
          phone: "31",
        },
        {
          id: 162,
          name: "New Caledonia",
          name_ascii: "New Caledonia",
          phone: "687",
        },
        {
          id: 172,
          name: "New Zealand",
          name_ascii: "New Zealand",
          phone: "64",
        },
        {
          id: 166,
          name: "Nicaragua",
          name_ascii: "Nicaragua",
          phone: "505",
        },
        {
          id: 163,
          name: "Niger",
          name_ascii: "Niger",
          phone: "227",
        },
        {
          id: 165,
          name: "Nigeria",
          name_ascii: "Nigeria",
          phone: "234",
        },
        {
          id: 171,
          name: "Niue",
          name_ascii: "Niue",
          phone: "683",
        },
        {
          id: 164,
          name: "Norfolk Island",
          name_ascii: "Norfolk Island",
          phone: "672",
        },
        {
          id: 121,
          name: "North Korea",
          name_ascii: "North Korea",
          phone: "850",
        },
        {
          id: 150,
          name: "Northern Mariana Islands",
          name_ascii: "Northern Mariana Islands",
          phone: "1-670",
        },
        {
          id: 168,
          name: "Norway",
          name_ascii: "Norway",
          phone: "47",
        },
        {
          id: 173,
          name: "Oman",
          name_ascii: "Oman",
          phone: "968",
        },
        {
          id: 179,
          name: "Pakistan",
          name_ascii: "Pakistan",
          phone: "92",
        },
        {
          id: 186,
          name: "Palau",
          name_ascii: "Palau",
          phone: "680",
        },
        {
          id: 184,
          name: "Palestinian Territory",
          name_ascii: "Palestinian Territory",
          phone: "970",
        },
        {
          id: 174,
          name: "Panama",
          name_ascii: "Panama",
          phone: "507",
        },
        {
          id: 177,
          name: "Papua New Guinea",
          name_ascii: "Papua New Guinea",
          phone: "675",
        },
        {
          id: 187,
          name: "Paraguay",
          name_ascii: "Paraguay",
          phone: "595",
        },
        {
          id: 175,
          name: "Peru",
          name_ascii: "Peru",
          phone: "51",
        },
        {
          id: 178,
          name: "Philippines",
          name_ascii: "Philippines",
          phone: "63",
        },
        {
          id: 182,
          name: "Pitcairn",
          name_ascii: "Pitcairn",
          phone: "870",
        },
        {
          id: 180,
          name: "Poland",
          name_ascii: "Poland",
          phone: "48",
        },
        {
          id: 185,
          name: "Portugal",
          name_ascii: "Portugal",
          phone: "351",
        },
        {
          id: 183,
          name: "Puerto Rico",
          name_ascii: "Puerto Rico",
          phone: "1-787 and 1-939",
        },
        {
          id: 188,
          name: "Qatar",
          name_ascii: "Qatar",
          phone: "974",
        },
        {
          id: 42,
          name: "Republic of the Congo",
          name_ascii: "Republic of the Congo",
          phone: "242",
        },
        {
          id: 189,
          name: "Reunion",
          name_ascii: "Reunion",
          phone: "262",
        },
        {
          id: 190,
          name: "Romania",
          name_ascii: "Romania",
          phone: "40",
        },
        {
          id: 192,
          name: "Russia",
          name_ascii: "Russia",
          phone: "7",
        },
        {
          id: 193,
          name: "Rwanda",
          name_ascii: "Rwanda",
          phone: "250",
        },
        {
          id: 26,
          name: "Saint Barthelemy",
          name_ascii: "Saint Barthelemy",
          phone: "590",
        },
        {
          id: 201,
          name: "Saint Helena",
          name_ascii: "Saint Helena",
          phone: "290",
        },
        {
          id: 120,
          name: "Saint Kitts and Nevis",
          name_ascii: "Saint Kitts and Nevis",
          phone: "1-869",
        },
        {
          id: 129,
          name: "Saint Lucia",
          name_ascii: "Saint Lucia",
          phone: "1-758",
        },
        {
          id: 142,
          name: "Saint Martin",
          name_ascii: "Saint Martin",
          phone: "590",
        },
        {
          id: 181,
          name: "Saint Pierre and Miquelon",
          name_ascii: "Saint Pierre and Miquelon",
          phone: "508",
        },
        {
          id: 238,
          name: "Saint Vincent and the Grenadines",
          name_ascii: "Saint Vincent and the Grenadines",
          phone: "1-784",
        },
        {
          id: 245,
          name: "Samoa",
          name_ascii: "Samoa",
          phone: "685",
        },
        {
          id: 206,
          name: "San Marino",
          name_ascii: "San Marino",
          phone: "378",
        },
        {
          id: 210,
          name: "Sao Tome and Principe",
          name_ascii: "Sao Tome and Principe",
          phone: "239",
        },
        {
          id: 194,
          name: "Saudi Arabia",
          name_ascii: "Saudi Arabia",
          phone: "966",
        },
        {
          id: 207,
          name: "Senegal",
          name_ascii: "Senegal",
          phone: "221",
        },
        {
          id: 191,
          name: "Serbia",
          name_ascii: "Serbia",
          phone: "381",
        },
        {
          id: 196,
          name: "Seychelles",
          name_ascii: "Seychelles",
          phone: "248",
        },
        {
          id: 205,
          name: "Sierra Leone",
          name_ascii: "Sierra Leone",
          phone: "232",
        },
        {
          id: 200,
          name: "Singapore",
          name_ascii: "Singapore",
          phone: "65",
        },
        {
          id: 212,
          name: "Sint Maarten",
          name_ascii: "Sint Maarten",
          phone: "599",
        },
        {
          id: 204,
          name: "Slovakia",
          name_ascii: "Slovakia",
          phone: "421",
        },
        {
          id: 202,
          name: "Slovenia",
          name_ascii: "Slovenia",
          phone: "386",
        },
        {
          id: 195,
          name: "Solomon Islands",
          name_ascii: "Solomon Islands",
          phone: "677",
        },
        {
          id: 208,
          name: "Somalia",
          name_ascii: "Somalia",
          phone: "252",
        },
        {
          id: 248,
          name: "South Africa",
          name_ascii: "South Africa",
          phone: "27",
        },
        {
          id: 90,
          name: "South Georgia and the South Sandwich Islands",
          name_ascii: "South Georgia and the South Sandwich Islands",
          phone: "",
        },
        {
          id: 122,
          name: "South Korea",
          name_ascii: "South Korea",
          phone: "82",
        },
        {
          id: 198,
          name: "South Sudan",
          name_ascii: "South Sudan",
          phone: "211",
        },
        {
          id: 68,
          name: "Spain",
          name_ascii: "Spain",
          phone: "34",
        },
        {
          id: 131,
          name: "Sri Lanka",
          name_ascii: "Sri Lanka",
          phone: "94",
        },
        {
          id: 197,
          name: "Sudan",
          name_ascii: "Sudan",
          phone: "249",
        },
        {
          id: 209,
          name: "Suriname",
          name_ascii: "Suriname",
          phone: "597",
        },
        {
          id: 203,
          name: "Svalbard and Jan Mayen",
          name_ascii: "Svalbard and Jan Mayen",
          phone: "47",
        },
        {
          id: 214,
          name: "Swaziland",
          name_ascii: "Swaziland",
          phone: "268",
        },
        {
          id: 199,
          name: "Sweden",
          name_ascii: "Sweden",
          phone: "46",
        },
        {
          id: 43,
          name: "Switzerland",
          name_ascii: "Switzerland",
          phone: "41",
        },
        {
          id: 213,
          name: "Syria",
          name_ascii: "Syria",
          phone: "963",
        },
        {
          id: 229,
          name: "Taiwan",
          name_ascii: "Taiwan",
          phone: "886",
        },
        {
          id: 220,
          name: "Tajikistan",
          name_ascii: "Tajikistan",
          phone: "992",
        },
        {
          id: 230,
          name: "Tanzania",
          name_ascii: "Tanzania",
          phone: "255",
        },
        {
          id: 219,
          name: "Thailand",
          name_ascii: "Thailand",
          phone: "66",
        },
        {
          id: 218,
          name: "Togo",
          name_ascii: "Togo",
          phone: "228",
        },
        {
          id: 221,
          name: "Tokelau",
          name_ascii: "Tokelau",
          phone: "690",
        },
        {
          id: 225,
          name: "Tonga",
          name_ascii: "Tonga",
          phone: "676",
        },
        {
          id: 227,
          name: "Trinidad and Tobago",
          name_ascii: "Trinidad and Tobago",
          phone: "1-868",
        },
        {
          id: 224,
          name: "Tunisia",
          name_ascii: "Tunisia",
          phone: "216",
        },
        {
          id: 226,
          name: "Turkey",
          name_ascii: "Turkey",
          phone: "90",
        },
        {
          id: 223,
          name: "Turkmenistan",
          name_ascii: "Turkmenistan",
          phone: "993",
        },
        {
          id: 215,
          name: "Turks and Caicos Islands",
          name_ascii: "Turks and Caicos Islands",
          phone: "1-649",
        },
        {
          id: 228,
          name: "Tuvalu",
          name_ascii: "Tuvalu",
          phone: "688",
        },
        {
          id: 241,
          name: "U.S. Virgin Islands",
          name_ascii: "U.S. Virgin Islands",
          phone: "1-340",
        },
        {
          id: 232,
          name: "Uganda",
          name_ascii: "Uganda",
          phone: "256",
        },
        {
          id: 231,
          name: "Ukraine",
          name_ascii: "Ukraine",
          phone: "380",
        },
        {
          id: 2,
          name: "United Arab Emirates",
          name_ascii: "United Arab Emirates",
          phone: "971",
        },
        {
          id: 77,
          name: "United Kingdom",
          name_ascii: "United Kingdom",
          phone: "44",
        },
        {
          id: 234,
          name: "United States",
          name_ascii: "United States",
          phone: "1",
        },
        {
          id: 233,
          name: "United States Minor Outlying Islands",
          name_ascii: "United States Minor Outlying Islands",
          phone: "1",
        },
        {
          id: 235,
          name: "Uruguay",
          name_ascii: "Uruguay",
          phone: "598",
        },
        {
          id: 236,
          name: "Uzbekistan",
          name_ascii: "Uzbekistan",
          phone: "998",
        },
        {
          id: 243,
          name: "Vanuatu",
          name_ascii: "Vanuatu",
          phone: "678",
        },
        {
          id: 237,
          name: "Vatican",
          name_ascii: "Vatican",
          phone: "379",
        },
        {
          id: 239,
          name: "Venezuela",
          name_ascii: "Venezuela",
          phone: "58",
        },
        {
          id: 242,
          name: "Vietnam",
          name_ascii: "Vietnam",
          phone: "84",
        },
        {
          id: 244,
          name: "Wallis and Futuna",
          name_ascii: "Wallis and Futuna",
          phone: "681",
        },
        {
          id: 66,
          name: "Western Sahara",
          name_ascii: "Western Sahara",
          phone: "212",
        },
        {
          id: 246,
          name: "Yemen",
          name_ascii: "Yemen",
          phone: "967",
        },
        {
          id: 249,
          name: "Zambia",
          name_ascii: "Zambia",
          phone: "260",
        },
        {
          id: 250,
          name: "Zimbabwe",
          name_ascii: "Zimbabwe",
          phone: "263",
        },
      ],
      weekDays: [
        {
          value: 1,
          abbr: "Mon",
          name: `${this.$t("weekDaysStrings.monday")}`,
        },
        {
          value: 2,
          abbr: "Tue",
          name: `${this.$t("weekDaysStrings.tuesday")}`,
        },
        {
          value: 3,
          abbr: "Wed",
          name: `${this.$t("weekDaysStrings.wednesday")}`,
        },
        {
          value: 4,
          abbr: "Thu",
          name: `${this.$t("weekDaysStrings.thursday")}`,
        },
        {
          value: 5,
          abbr: "Fri",
          name: `${this.$t("weekDaysStrings.friday")}`,
        },
        {
          value: 6,
          abbr: "Sat",
          name: `${this.$t("weekDaysStrings.saturday")}`,
        },
        {
          value: 7,
          abbr: "Sun",
          name: `${this.$t("weekDaysStrings.sunday")}`,
        },
      ],
      headers: {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + self.$store.getters.StateUser?.token_data?.access_token,
          "accept-language": localStorage.lang,
          "CLIENT-ID": process.env.VUE_APP_CLIENTID,
          "Client-Secret": process.env.VUE_APP_ClientSecret,
          "USER-TIMEZONE": Intl.DateTimeFormat().resolvedOptions().timeZone,
          "CUSTOM-HOST": JSON.parse(localStorage.getItem("vuex"))?.auth?.user
            ?.user?.custom_host
            ? JSON.parse(localStorage.getItem("vuex")).auth.user.user
                .custom_host
            : "",
        },
      },
      unAuthHeaders: {
        headers: {
          "Content-Type": "application/json",
          "accept-language": localStorage.lang,
          "CLIENT-ID": process.env.VUE_APP_CLIENTID,
          "Client-Secret": process.env.VUE_APP_ClientSecret,
          "USER-TIMEZONE": Intl.DateTimeFormat().resolvedOptions().timeZone,
          "CUSTOM-HOST": JSON.parse(localStorage.getItem("vuex"))?.auth?.user
            ?.user?.custom_host
            ? JSON.parse(localStorage.getItem("vuex")).auth.user.user
                .custom_host
            : "",
        },
      },
      model: {},
      modelList: [],
      langs: [
        { lang: "en", Name: "English" },
        { lang: "ar", Name: "عربي" },
      ],
      isEdit: false,
      currentPage: 1,
      totalPages: 10,
      AddNewTitle: "",
      EditExistTitle: "",
      rememberme: false,
      username: "",
    }
  },
  methods: {
    ...mapActions(["RemoveCurrentUser"]), // Add your action here
    changeLang() {
      //https://vee-validate.logaretm.com/v2/guide/localization.html#localization-api
      this.$i18n.locale = this.$i18n.locale == "ar" ? "en" : "ar"
      localStorage.lang = this.$i18n.locale
      this.$validator.localize(
        `${localStorage.lang}`,
        localStorage.lang == "en" ? this.dictionary.en : this.dictionary.ar
      )
      this.langPathImg =
        localStorage.lang == "en"
          ? "/assets/images/ar.webp"
          : "/assets/images/en.webp"
      this.langeName = localStorage.lang == "en" ? "  العربية" : "  English"

      if (localStorage.lang == "en") {
        document.body.classList.add("site-ltr")
        document.body.classList.remove("site-rtl")
        this.$vuetify.rtl = false
        // document.title = "";
      } else {
        document.body.classList.add("site-rtl")
        document.body.classList.remove("site-ltr")
        this.$vuetify.rtl = true
        // document.title = "";
      }
    },
    ConvertToJson: function (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return ""
      }
      return JSON.parse(str)
    },
    /**
     * To format errors and display it nicely
     * @param error error response object
     */
    formatErrors(error) {
      const errorsArray = Array.isArray(
        error?.response.data.errors || error?.data.errors
      )
        ? error.response.data.errors
        : Object.entries(error.response.data.errors)
      let errors = ""
      errorsArray.forEach(value => {
        const error = Object.values(value)
        errors += `<li>${error}</li>`
      })
      this.setAlert("error", `${this.$t("Oops")}`, errors, 3000)
    },
    /**
     * Show sweetalert2 Error pop up
     * @param error string
     */
    showAlert(message, type = "success") {
      this.setAlert(
        type,
        `${this.$t(type === "error" ? "messages.ops" : "messages.success")}`,
        this.$t(message),
        type === "error" ? 3000 : 2000
      )
    },
    load: function () {
      var self = this
      if (self.queryUrl && self.queryUrl != "") {
        self.ShowLoading()
        this.axios
          .get(self.queryUrl, self.headers)
          .then(function (res) {
            self.modelList = res.data
            //self.query.PageNumber = res.data.PageNumber;
            //self.query.PageSize = res.data.PageSize;
            //self.total_rows = res.data.Total;
            //self.total_pages = Math.ceil(self.total_rows / res.data.PageSize);
            self.afterLoad()
            //if (self.total_pages > 0 && self.query.PageNumber <= 1) {
            //    setTimeout(function () {
            //        buildPage(res.data.Total, res.data.PageSize);
            //    }, 100);
            //}
          })
          .catch(function (err) {
            self.displayError(err)
            return console.log(err)
          })
          .finally(function () {
            return self.HideLoading()
          })
      }
    },
    afterLoad: function () {},
    displayError: function (err) {
      let self = this
      if (err?.response?.data?.errors) {
        let messages = err?.response?.data //self.ConvertToJson(err?.response?.data);
        if (messages != "") {
          let errorstr =
            localStorage.lang == "en"
              ? `<div style="text-align:left;width:125%;">   <ul style="padding-left:14px;">`
              : `<div style="text-align: right;width: 119%;margin-right: -3px;">   <ul style="padding-right: 0px;margin-right: -36px;">`
          if (Array.isArray(err?.response?.data?.errors)) {
            for (let i = 0; i < messages?.errors.length; i++) {
              for (let k in messages?.errors[i]) {
                errorstr += `        <li>` + messages?.errors[i][k] + `</li>`
              }
            }
          } else {
            errorstr += `        <li>` + messages?.errors + `</li>`
          }
          errorstr += `    </ul></div>`
          self.notify(errorstr, "error")
        } else {
          self.notify(self.$t("ConnectionError"), "error")
        }
      }
    },
    ShowLoading: function () {
      let self = this
      self.$root.isLoading = true
      //self.loader = this.$loading.show({
      //        // Optional parameters
      //        container: null,//self.$refs.formContainer,
      //        loader:"bars",
      //        width:170,
      //        height:170,
      //        color:"##007bff"
      //        //canCancel: true,
      //        //onCancel: this.onCancel,
      //    });
    },
    HideLoading: function () {
      let self = this
      self.$root.isLoading = false
      //self.loader.hide();
    },
    notify: function (message, fn = "error") {
      let self = this
      self.$snotify.clear()
      if (fn == "success") {
        self.$snotify.success(message, {
          timeout: 3000,
          showProgressBar: true,
          //closeOnClick: false,
          pauseOnHover: true,
        })
      } else if (fn == "warning") {
        self.$snotify.warning(message, {
          timeout: 3000,
          showProgressBar: true,
          //closeOnClick: false,
          pauseOnHover: true,
        })
      } else {
        self.$snotify.html(message, {
          type: "error",
          timeout: 3000,
          showProgressBar: true,
          //closeOnClick: false,
          pauseOnHover: true,
        })
      }
    },
    showCreateForm: function () {
      var self = this
      self.isEdit = false
      self.showValidMessage = false
      self.model = {}
      self.createMode()
      self.ResetFilePreview()
      jQuery("#addEditModel").modal("show")
      setTimeout(function () {
        //self.loadFilesPreview(self.model);
      }, 300)
    },
    showEditForm: function (n) {
      var self = this
      self.isEdit = true
      self.ShowLoading()
      self.closeErrorAlert()
      self.ResetFilePreview()
      self.model = JSON.parse(JSON.stringify(n))
      jQuery("#addEditModel").modal("show")
      setTimeout(function () {
        //self.loadFilesPreview(self.model);
      }, 500)
      self.HideLoading()
    },
    showDeleteConfirm: function (n) {
      var self = this
      self.model = n
      jQuery("#DeleteConfirmation").modal("show")
      self.closeErrorAlert()
    },
    deleteItem: function () {
      let self = this
      self.ShowLoading()
      var url = self.deleteUrl.concat(self.model.id)
      self.preDelete()
      self.axios
        .delete(url, self.headers)
        .then(function (res) {
          /*if (res.data.success) {*/
          self.load()
          self.model = {}
          jQuery("#DeleteConfirmation").modal("hide")
          self.notify($t("DeleteSuccess"), "success")
          //}
          //else {
          //    self.notify(self.$t(res.data.Messages[0].Message), "error");
          //}
        })
        .catch(function (err) {
          self.displayError(err)
          return console.log(err)
        })
        .finally(function () {
          return self.HideLoading()
        })
    },
    closeDeletConfirmation: function () {
      jQuery("#DeleteConfirmation").modal("hide")
    },
    ResetFilePreview: function () {},
    Insert: function () {
      var self = this
      self.ShowLoading()
      let url = self.insertUrl
      self.model.Id = 0
      self.preInsert()
      this.axios
        .post(url, self.model, self.headers)
        .then(function (res) {
          if (res.data.success) {
            //self.load();
            self.model = {}
            //Slidepage();
            self.closeForm()
            self.notify(self.successmsg, "success")
          } else {
            self.notify(self.$t(res.data.Messages[0].Message), "error")
          }
        })
        .catch(function (err) {
          self.displayError(err)
          return console.log(err)
        })
        .finally(function () {
          return self.HideLoading()
        })
    },
    Update: function () {
      var self = this
      self.ShowLoading()
      let url = self.updateUrl + self.model.id + "/"
      self.preUpdate()
      this.axios
        .post(url, self.model, self.headers)
        .then(function (res) {
          if (res.data.success) {
            //self.load();
            self.model = {}
            //Slidepage();
            self.closeForm()
            self.notify(self.successmsg, "success")
          } else {
            self.notify(self.$t(res.data.Messages[0].Message), "error")
          }
        })
        .catch(function (err) {
          self.displayError(err)
          return console.log(err)
        })
        .finally(function () {
          return self.HideLoading()
        })
    },
    closeForm: function () {
      var self = this
      self.model = {}
      self.isEdit = false
      jQuery("#addEditModel").modal("hide")
      self.clearvalidation()
      self.closeErrorAlert()
      self.load()
    },
    preInsert: function () {},
    preUpdate: function () {},
    preDelete: function () {},
    prePreview: function () {},
    createMode: function () {},
    clearvalidation() {
      let self = this
      setTimeout(function () {
        //self.$validator.clean();
        self.$validator.reset()
        self.$validator.errors.clear()
      }, 100)
    },
    customValidate: function () {
      let valid = true
      return valid
    },
    closeErrorAlert: function () {
      $("#errorAlert").hide("fade")
    },
    save: function () {
      var self = this
      self.showValidMessage = true
      let valid = self.customValidate()
      self.$validator.validate().then(function (result) {
        //// when use html control
        if (!result || !valid) {
          ////not valid
          let detail = $(".form--error").first()[0]
          if (detail) {
            detail.scrollIntoView({ behavior: "smooth", block: "end" })
          }
          return
        }
        $(".form--error").removeClass("form--error")
        self.closeErrorAlert()

        if (self.isEdit) {
          self.Update()
        } else {
          self.Insert()
        }
        //this.closeForm();
      })
    },
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage
    },
    async getconfigurations() {
      var self = this
      const localConfigs = localStorage.getItem("configs")
      if (localConfigs) localStorage.removeItem("configs")
      let data = {}
      let url = process.env.VUE_APP_APIEndPoint + "institutes/configs/"
      let response = await this.axios.post(url, data, this.headers)
      if (response) {
        if (
          response.headers["institute-default-language"] &&
          response.headers["institute-default-language"] !==
            self.$i18n.locale &&
          !localStorage.getItem("preferred-lang")
        ) {
          self.changeLang()
        }
        let configs = response.data.configurations
        localStorage.setItem("configs", JSON.stringify(configs))
        localStorage.setItem(
          "institute_configs",
          JSON.stringify(response.data.institute)
        )
        window.dispatchEvent(
          new CustomEvent("localstorageChange", {
            detail: {
              storage: localStorage,
            },
          })
        )
        if (configs && configs.colors) {
          this.addColors(configs.colors.config_value)
        }
        if (configs && configs?.institute_name) {
          document.title = configs.institute_name.config_value
        }
        return response.data.configurations
      }
    },
    addColors(colors) {
      const style = document.createElement("style")
      style.innerHTML = `
      :root{
          ${colors}
      }
      .acOpenButton-0-0-9{
          background: var(--main-color) !important;
      }
      `
      document.head.appendChild(style)
    },
    async logout(login = false) {
      store.dispatch("resetSelections")
      store.dispatch("clearAllKidsSelections")
      this.$router.push("/")
      var self = this
      // self.ShowLoading();
      let url = process.env.VUE_APP_APIEndPoint + "auth/revoke_token/"
      self.axios
        .post(url, {}, self.headers)
        .then(function (res) {
          if (res.data.success) {
            self.model = {}
            self.notify(self.$t("logout success"), "success")
            let userType = JSON.parse(localStorage.vuex).auth.user.user
              .user_type
            self.RemoveCurrentUser()
            self.$store.commit("setTutorData", null)
            self.$store.commit("setCoursesDiscount", null)
            window.dispatchEvent(
              new CustomEvent("loginModalChange", {
                detail: {
                  opened: true,
                  tab: 0,
                },
              })
            )
            return
          } else {
            self.notify(self.$t(res.data.Messages[0].Message), "error")
          }
        })
        .catch(function (err) {
          self.displayError(err)
        })
        .finally(function () {
          self.RemoveCurrentUser()
          return self.HideLoading()
        })
    },
    loadLookups() {},
    calculate_age(dob) {
      var diff_ms = Date.now() - new Date(dob).getTime()
      var age_dt = new Date(diff_ms)

      return Math.abs(age_dt.getUTCFullYear() - 1970)
    },
    AfterMountedFn() {},
    toggleItemInArray(array, value) {
      var index = array.indexOf(value)

      if (index === -1) {
        array.push(value)
      } else {
        array.splice(index, 1)
      }
    },
    showTerms() {
      jQuery("#termsModal").modal("show")
    },
    showPrivacy() {
      jQuery("#privacyModal").modal("show")
    },
    getMomentEpoch(time, format) {
      return (
        moment(time, format)
          // .tz(this.headers.headers["USER-TIMEZONE"])
          .unix()
      )
    },
    getCurrentEpoch() {
      return (
        moment(
          `${moment()
            // .tz(this.headers.headers["USER-TIMEZONE"])
            .format("Y-M-D HH:mm:ss")}`,
          "Y-M-D HH:mm:ss"
        )
          // .tz(this.headers.headers["USER-TIMEZONE"])
          .unix()
      )
    },

    setAlert(alertIcon, alertTitle, alertText, alertTimer, showAlert = true) {
      this.$store.dispatch("toggleAlert", showAlert)
      this.$store.dispatch("setAlertIcon", alertIcon)
      this.$store.dispatch("setAlertTitle", alertTitle)
      this.$store.dispatch("setAlertText", alertText)
      this.$store.dispatch("setAlertTimer", alertTimer)
    },
  },

  created() {},
  mounted() {
    localStorage.lang = this.$i18n.locale

    this.$validator.localize(
      `${localStorage.lang}`,
      localStorage.lang == "en" ? this.dictionary.en : this.dictionary.ar
    )
    if (localStorage.lang == "en") {
      document.body.classList.add("site-ltr")
      document.body.classList.remove("site-rtl")
    } else {
      document.body.classList.add("site-rtl")
      document.body.classList.remove("site-ltr")
      // document.title = "الكتاب";
    }
    if (
      JSON.parse(localStorage.getItem("configs"))?.institute_name?.config_value
    ) {
      document.title = JSON.parse(
        localStorage.getItem("configs")
      ).institute_name.config_value
    }
    this.loadLookups()
    this.load()
    this.rememberme = localStorage.rememberme
    this.username = localStorage.username
    this.AfterMountedFn()
    //console.log(this.$t("hello") + process.env.VUE_APP_APIEndPoint)
    this.$store.dispatch("toggleAlert", false)
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated
    },
  },
}
