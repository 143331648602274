<template>
  <v-app id="app" v-bind:class="$route.name">
    <!--:class='$i18n.locale=="ar"?"site-ltr":"site-rtl"'-->
    <!--<loading v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"></loading>-->
    <!--<div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
    </div>-->
    <div class="vld-parent">
      <loading
        :active="$root.isLoading"
        loader="bars"
        :width="170"
        :height="170"
        color="#007bff"
        :is-full-page="true"
      />
    </div>
    <vue-snotify></vue-snotify>
    <Alert />

    <component :is="layout">
      <router-view :key="$route.fullPath" class="mt-16" />
    </component>
    <a
      target="_blank"
      v-bind:href="`https://wa.me/${configurations.whatsapp_number.config_value}`"
      v-if="configurations.whatsapp_number"
    >
      <div
        class="whatsapp-btn-container"
        :style="{
          bottom: isMainDomain ? '90px' : '10px',
        }"
      >
        <div class="whatsapp-btn">
          <v-icon size="30px" color="#fff">mdi-whatsapp</v-icon>
        </div>
      </div>
    </a>
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import anonymous_layout from "./layouts/anonymous_layout.vue"
import kids_layout from "./layouts/kids_layout.vue"
import operator_layout from "./layouts/operator_layout.vue"
import institute_admin_layout from "./layouts/institute_admin_layout.vue"
import student_layout from "./layouts/student_layout.vue"
import tutor_layout from "./layouts/tutor_layout.vue"
import zoom_layout from "./layouts/zoom_layout.vue"
import alarqam_layout from "./layouts/alarqam_layout.vue"

const default_layout = "anonymous"
const arqam_layout = "alarqam"
import GlobalMixin from "@/GlobalMixin.js"

import Loading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"
import Alert from "./components/Alert.vue"

export default {
  mixins: [GlobalMixin],
  name: "App",
  components: {
    anonymous_layout,
    kids_layout,
    operator_layout,
    institute_admin_layout,
    student_layout,
    tutor_layout,
    zoom_layout,
    alarqam_layout,
    Loading,
    Alert,
  },
  data() {
    return {
      configurations: {},
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(to, from, next) {
        if (from.name == "meeting") {
          this.$router.go()
          next()
        }
        if (from.name == "Landing") {
          this.$router.go()
          next()
        }
      },
    },
  },
  mounted() {
    this.getconfigurations().then(res => {
      this.configurations = res
      localStorage.setItem("configs", JSON.stringify(this.configurations))
      if (this.configurations && this.configurations?.institute_name) {
        document.title = this.configurations.institute_name.config_value
      }
    })
    this.getVersionNumber()
  },
  methods: {
    getVersionNumber() {
      this.axios(
        `${process.env.VUE_APP_APIEndPoint}institutes/version/`,
        this.headers
      ).then(response => {
        const version = +localStorage.getItem("version")
        if (response.data.data.web_app_version !== version) {
          localStorage.setItem("version", response.data.data.web_app_version)
          window.location.reload(true)
        }
      })
    },
  },
  computed: {
    isMainDomain() {
      return (
        window.location.href.includes("https://qc.alkuttab.io/") ||
        window.location.href.includes("https://preprod.alkuttab.io/") ||
        window.location.href.includes("https://alkuttab.io/")
      )
    },
    layout() {
      let self = this
      const absoluteURL = new URL(window.location.origin).href
      if (
        absoluteURL.includes("alarqam") &&
        self.$route.meta.layout == "global"
      ) {
        if (self.isLoggedIn) {
          return self.$store.getters.StateUser.user.category + "_layout"
        } else {
          return arqam_layout + "_layout"
        }
      } else if (self.$route.meta.layout == "global") {
        if (self.isLoggedIn) {
          return self.$store.getters.StateUser.user.category + "_layout"
        } else {
          return default_layout + "_layout"
        }
      }
      return (self.$route.meta.layout || default_layout) + "_layout"
    },
  },
}
</script>
<style lang="scss">
@import "~vue-snotify/styles/material.css";
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

body {
  font-display: swap;
  &.site-ltr {
    *:not(i) {
      font-family: "Nunito", sans-serif !important;
    }
  }
  &.site-rtl {
    *:not(i) {
      font-family: "Tajawal" !important;
      font-weight: 500;
    }
  }
  padding-top: 97px;
}

.tutor--profile-model--footer {
  position: relative;
}

.webinars-status {
  display: flex;
  gap: 2rem;
  list-style: none;
  padding: 0;
  align-items: center;
}

.webinars-status li {
  display: inline-flex;
  gap: 1rem;
  align-items: center;
}

.webinars-status li::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.webinars-status--running::before {
  background-color: #a2f373;
}

.webinars-status--upcoming::before {
  background-color: #f7f782;
}

.whatsapp-btn-container {
  position: fixed;
  right: 85px;
  z-index: 1000;
  .whatsapp-btn {
    background-color: green;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    padding: 10px;
    box-shadow: 0 0 8px rgb(0 0 0 / 30%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* zoom style */
html,
body {
  min-width: 5px !important;
}

body {
  overflow: auto !important;
}

body > #zmmtg-root {
  display: none !important;
}

#zmmtg-root,
.meeting-client,
.meeting-client-inner {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}

#wc-footer {
  bottom: auto !important;
  width: 100% !important;
}

#dialog-join {
  width: 100% !important;
}

#sv-active-video,
.active-main,
#sv-active-speaker-view,
.main-layout {
  height: 100% !important;
  width: 100% !important;
}

.suspension-window {
  transform: translate(-444px, 10px) !important;
}

.v-application ul {
  padding-left: 0 !important;
}

.zmwebsdk-makeStyles-video-43 {
  width: 100%;
}

.zmwebsdk-makeStyles-root-19 {
  width: 50rem !important;
  height: 47vh !important;
}

@media (max-width: 800px) {
  .zmwebsdk-makeStyles-root-19 {
    width: 30rem !important;
    height: 50vh !important;
  }

  div#meetingSDKElement {
    margin: 6rem auto 0 auto !important;
  }
  .header.active {
    z-index: 999999;
  }
}

#transition-popper {
  z-index: 999;
}

/* #zmmtg-root {
  position: unset !important;
}
#zmmtg-root,
.meeting-client,
.meeting-client-inner {
  width: 100% !important;
  height: 760px !important;
  position: relative !important;
}

#wc-footer {
  bottom: auto !important;
  width: 100% !important;
}
#wc-container-right {
  top: 181px !important;
}

#dialog-join {
  width: 100% !important;
}

#sv-active-video,
.active-main,
#sv-active-speaker-view,
.main-layout {
  height: 70vh !important;
  width: 100% !important;
}

.suspension-window {
  transform: translate(-444px, 10px) !important;
}
.Pane.horizontal.Pane2 {
  top: 247px;
} */
.v-application {
  background: transparent !important;
}

@keyframes shape3dCircle {
  0% {
    transform: translateX(50%) translateY(0);
  }

  100% {
    transform: translateX(50%) translateY(-80px);
  }
}

@keyframes shape3dCircleLtr {
  0% {
    transform: rotateY(180deg) translateX(50%) translateY(0);
  }

  100% {
    transform: rotateY(180deg) translateX(50%) translateY(-80px);
  }
}

@keyframes objectShadow {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.4;
    transform: scale(0.85);
  }
}

@keyframes uploadArrow {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-5px);
  }
}

@keyframes pulse-effect {
  0% {
    box-shadow: 0 0 0 0 white;
  }

  70% {
    box-shadow: 0 0 0 20px transparent;
  }

  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}

.FancyHome {
  margin-top: -95px;

  header {
    background-color: transparent;
    box-shadow: none;
    padding: 0;

    .logo {
      max-height: 100%;
      width: auto;
    }

    @media (min-width: 992px) {
      .header--nav-main > li > a:not(.nav-btn-link) {
        color: white;
      }
    }

    &.sticky {
      background: #fff;
      box-shadow: 0 13px 26px rgba(0, 0, 0, 0.16);
      z-index: 9999;

      @media (min-width: 992px) {
        .header--nav-main > li > a:not(.nav-btn-link) {
          color: black;
        }
      }
    }
  }
}
.header {
  z-index: 5;
  padding: 0;
}
.meetingSDKElement {
  z-index: 0 !important;
}
.zmwebsdk-makeStyles-chatCustomize-3 {
  z-index: 1 !important;
}
.modal,
.mx-datepicker-popup {
  z-index: 99999 !important;
}
.snotify {
  z-index: 999999;
}
table {
  thead {
    tr {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }
  tbody {
    td {
      button {
        margin: 5px 0;
      }
    }
  }
}
.header--wrapper {
  align-items: center;
}
.v-dialog__content {
  // z-index: 1000000 !important;
  button.primary--text {
    background-color: #1976d2 !important;
    color: white !important;
  }
}
.v-date-picker-table {
  min-height: 320px;
}
</style>
