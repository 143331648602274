<template>
  <div class="tutor-layout">
    <v-progress-linear
      v-if="isRouteLoading"
      indeterminate
      color="cyan"
      style="z-index: 2000; top: -97px"
    ></v-progress-linear>
    <Header></Header>
    <transition name="slide" mode="out-in">
      <slot></slot>
    </transition>
    <!-- Start Footer-->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/Header.vue"
// import Footer from "@/components/Footer.vue";
import GlobalMixin from "@/GlobalMixin.js"

export default {
  name: "student_layout",
  mixins: [GlobalMixin],
  components: {
    Header,
    // Footer,
  },
  computed: {
    isRouteLoading() {
      return this.$store.getters.GET_IS_ROUTE_LOADING
    },
  },
}
</script>
<style scoped lang="scss">
.tutor-avatar {
  width: 35px;
  height: 35px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid var(--auth-main-color);
  margin-left: 5px;
}
</style>
