const state = {
  filters: [], // Store the filters locally
}

const mutations = {
  // Mutation to set the filters in the state
  SET_FILTERS(state, filters) {
    state.filters = filters
  },
}

const actions = {
  // Action to fetch filters locally (with argument)
  getFilters({ commit }, filters) {
    // Commit the mutation to update the state with the passed filters
    commit("SET_FILTERS", filters)
  },
}

const getters = {
  // Getter to access filters from the state
  filters: state => state.filters,
}

export default {
  namespaced: true, // Namespace this module
  state,
  mutations,
  actions,
  getters,
}
