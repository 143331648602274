<template>
  <button v-if="institute_configs.currency">
    {{ institute_configs.currency }}
  </button>
</template>

<script>
export default {
  name: "CurrencyList",
  data() {
    return {
      institute_configs: JSON.parse(localStorage.getItem("institute_configs")),
    }
  },
}
</script>

<style lang="scss" scoped></style>
