import Vue from "vue"
import Router from "vue-router"
import store from "../store"

Vue.use(Router)

const checkOperator = (to, from, next) => {
  const dev = "http://localhost:8080/operatorlogin"
  const qc = "https://qc.alkuttab.io/operatorlogin"
  const pre_prod = "https://preprod.alkuttab.io/operatorlogin"
  const website = "https://alkuttab.io/operatorlogin"
  if (
    window.location.href === website ||
    window.location.href === qc ||
    window.location.href === pre_prod ||
    window.location.href === dev
  ) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isAuthenticated) {
        if (
          to.matched.some(
            record =>
              record.meta.NotSocial == true &&
              store.getters.StateUser.user.user_type.indexOf("_") != -1
          )
        ) {
          next("/")
          return
        }
        if (
          to.matched.some(record => record.meta.layout == "global") ||
          to.matched.some(
            record =>
              record.meta.layout == store.getters.StateUser.user.category
          )
        ) {
          next()
          return
        } else {
          next("/")
          return
        }
      }
      window.dispatchEvent(
        new CustomEvent("loginModalChange", {
          detail: {
            opened: true,
            tab: 0,
          },
        })
      )
      return
    } else if (to.matched.some(record => record.meta.guest)) {
      if (!store.getters.isAuthenticated) {
        next()
        return
      }
      next("/")
    } else {
      next()
    }
  }
}

const checkLogin = (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      if (
        to.matched.some(
          record =>
            record.meta.NotSocial == true &&
            store.getters.StateUser.user.user_type.indexOf("_") != -1
        )
      ) {
        next("/")
        return
      }
      if (
        to.matched.some(record => record.meta.layout == "global") ||
        to.matched.some(
          record => record.meta.layout == store.getters.StateUser.user.category
        )
      ) {
        next()
        return
      } else {
        next("/")
        return
      }
    }
    window.dispatchEvent(
      new CustomEvent("loginModalChange", {
        detail: {
          opened: true,
          tab: 0,
        },
      })
    )
    return
  } else if (to.matched.some(record => record.meta.guest)) {
    if (!store.getters.isAuthenticated) {
      next()
      return
    }
    next("/")
  } else {
    next()
  }
}

export default new Router({
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" }
  },
  routes: [
    {
      path: "/",
      name: "FancyHome",
      component: () =>
        import(/* webpackChunkName: "fancyHome" */ "../views/fancyHome.vue"),
      // meta: { layout: "global" },
      beforeEnter: checkLogin,
    },
    {
      path: "/tutors",
      name: "Tutors",
      component: () =>
        import(
          /* webpackChunkName: "TutorsView" */ "../views/NewReservation/TutorsView.vue"
        ),
      meta: { layout: "global" },
    },
    {
      path: "/courses",
      name: "KidsCoursesList",
      component: () =>
        import(
          /* webpackChunkName: "KidsCoursesListView" */ "../views/KidsReservation/KidsCoursesListView.vue"
        ),
      meta: { layout: "global" },
      children: [
        {
          path: "programs",
          name: "KidsProgramsList",
          component: () =>
            import(
              /* webpackChunkName: "KidsProgramsList" */ "../views/KidsReservation/KidsProgramsListView.vue"
            ),
          meta: { layout: "global", requiresAuth: true },
          beforeEnter(to, from, next) {
            if (!store.getters.isAuthenticated)
              return window.dispatchEvent(
                new CustomEvent("loginModalChange", {
                  detail: {
                    opened: true,
                    tab: 0,
                  },
                })
              )
            if (!store.getters.GET_SELECTED_KIDS_COURSES.length) {
              store.dispatch("clearAllKidsSelections")
              return next({ name: "KidsCoursesList" })
            }
            next()
          },
        },
        {
          path: "kids",
          name: "KidsCourses",
          component: () =>
            import(
              /* webpackChunkName: "KidsCoursesView" */ "../views/KidsReservation/KidsCoursesView.vue"
            ),
          meta: { layout: "global", requiresAuth: true },
          beforeEnter(to, from, next) {
            if (!store.getters.isAuthenticated)
              return window.dispatchEvent(
                new CustomEvent("loginModalChange", {
                  detail: {
                    opened: true,
                    tab: 0,
                  },
                })
              )
            if (!store.getters.GET_SELECTED_KIDS_COURSES.length) {
              store.dispatch("clearAllKidsSelections")
              return next({ name: "KidsCoursesList" })
            }
            next()
          },
        },
        {
          path: "interview",
          name: "ScheduleInterview",
          component: () =>
            import(
              /* webpackChunkName: "ScheduleInterviewView" */ "../views/KidsReservation/ScheduleInterviewView.vue"
            ),
          meta: { layout: "global", requiresAuth: true },
          beforeEnter(to, from, next) {
            if (!store.getters.isAuthenticated)
              return window.dispatchEvent(
                new CustomEvent("loginModalChange", {
                  detail: {
                    opened: true,
                    tab: 0,
                  },
                })
              )
            if (!store.getters.GET_SELECTED_MATRIX.length) {
              store.dispatch("clearAllKidsSelections")
              return next({ name: "KidsCoursesList" })
            }
            next()
          },
        },
        {
          path: "reservation",
          name: "KidsCheckout",
          component: () =>
            import(
              /* webpackChunkName: "KidsCheckout" */ "../views/KidsReservation/KidsCheckoutView.vue"
            ),
          meta: { layout: "global", requiresAuth: true },
          beforeEnter(to, from, next) {
            if (!store.getters.isAuthenticated)
              return window.dispatchEvent(
                new CustomEvent("loginModalChange", {
                  detail: {
                    opened: true,
                    tab: 0,
                  },
                })
              )
            if (!store.getters.GET_WEBINAR_ID) {
              return next({ name: "ScheduleInterview" })
            }
            next()
          },
        },
      ],
    },
    {
      path: "/reservation/:id",
      redirect: "/reservation/:id/courses",
      name: "Reservation",
      component: () =>
        import(
          /* webpackChunkName: "ReservationView" */ "../views/NewReservation/ReservationView.vue"
        ),
      meta: { layout: "global", requiresAuth: true },
      children: [
        {
          name: "Courses",
          path: "courses",
          component: () =>
            import(
              /* webpackChunkName: "CoursesView" */ "../views/NewReservation/CoursesView.vue"
            ),
          meta: { layout: "global", requiresAuth: true },
        },
        {
          name: "ProgramsList",
          path: "programs",
          component: () =>
            import(
              /* webpackChunkName: "ProgramsView" */ "../views/NewReservation/ProgramsView.vue"
            ),
          meta: { layout: "global", requiresAuth: true },
          beforeEnter(to, from, next) {
            if (!store.getters.isAuthenticated)
              window.dispatchEvent(
                new CustomEvent("loginModalChange", {
                  detail: {
                    opened: true,
                    tab: 0,
                  },
                })
              )
            if (!store.getters.GET_SELECTED_COURSES.length) {
              store.dispatch("resetSelections")
              return next({ name: "Tutors" })
            }
            next()
          },
        },
        {
          name: "SessionsList",
          path: "sessions",
          component: () =>
            import(
              /* webpackChunkName: "SessionsView" */ "../views/NewReservation/SessionsView.vue"
            ),
          meta: { layout: "global", requiresAuth: true },
          beforeEnter(to, from, next) {
            if (!store.getters.isAuthenticated)
              return window.dispatchEvent(
                new CustomEvent("loginModalChange", {
                  detail: {
                    opened: true,
                    tab: 0,
                  },
                })
              )
            if (!store.getters.GET_SELECTED_PROGRAM) {
              store.dispatch("resetSelections")
              return next({ name: "Tutors" })
            }
            next()
          },
        },
        {
          name: "Payment",
          path: "checkout",
          component: () =>
            import(
              /* webpackChunkName: "PaymentView" */ "../views/NewReservation/PaymentView.vue"
            ),
          meta: { layout: "global", requiresAuth: true },
          beforeEnter(to, from, next) {
            if (!store.getters.isAuthenticated)
              return window.dispatchEvent(
                new CustomEvent("loginModalChange", {
                  detail: {
                    opened: true,
                    tab: 0,
                  },
                })
              )
            if (!store.getters.GET_SELECTED_SLOTS.length) {
              store.dispatch("resetSelections")
              return next({ name: "Tutors" })
            }
            next()
          },
        },
      ],
    },
    {
      path: "/TutorEvents",
      name: "TutorEvents",
      component: () =>
        import(
          /* webpackChunkName: "TutorEvents" */ "../views/TutorEvents.vue"
        ),
      meta: { layout: "global" },
      beforeEnter: checkLogin,
    },
    {
      path: "/EventSample",
      name: "EventSample",
      component: () =>
        import(
          /* webpackChunkName: "EventSample" */ "../views/EventSample.vue"
        ),
      meta: { layout: "global" },
      beforeEnter: checkLogin,
    },
    {
      path: "/operatorlogin",
      name: "OperatorLogin",
      component: () =>
        import(
          /* webpackChunkName: "OperatorLogin" */ "../views/OperatorLogin.vue"
        ),
      meta: { layout: "anonymous", guest: true },
      beforeEnter: checkOperator,
    },
    {
      path: "/institute-login",
      name: "instituteAdminLogin",
      component: () =>
        import(
          /* webpackChunkName: "instituteAdminLogin" */ "../views/instituteAdminLogin.vue"
        ),
      meta: { layout: "anonymous", guest: true },
      beforeEnter: checkLogin,
    },
    {
      path: "/ForgetPassword",
      name: "ForgetPassword",
      component: () =>
        import(
          /* webpackChunkName: "ForgetPassword" */ "../views/ForgetPassword.vue"
        ),
      meta: { layout: "anonymous", guest: true },
      beforeEnter: checkLogin,
    },
    {
      path: "/UpdatePassword",
      name: "UpdatePassword",
      component: () =>
        import(
          /* webpackChunkName: "UpdatePassword" */ "../views/UpdatePassword.vue"
        ),
      meta: { layout: "global", requiresAuth: true, NotSocial: true },
      beforeEnter: checkLogin,
    },
    {
      path: "/Profile",
      name: "Profile",
      component: () =>
        import(/* webpackChunkName: "Profile" */ "../views/Profile.vue"),
      meta: { layout: "global", requiresAuth: true },
      beforeEnter: checkLogin,
    },
    {
      path: "/meeting",
      name: "meeting",
      component: () =>
        import(/* webpackChunkName: "Meeting" */ "../views/Meeting.vue"),
      meta: { layout: "global" },
      beforeEnter: checkLogin,
    },
    {
      path: "/Webinars",
      name: "Webinars",
      component: () =>
        import(/* webpackChunkName: "Webinars" */ "../views/Webinars.vue"),
      // meta: { guest: true },
      // component: Webinars,
      meta: { layout: "global", guest: true },
      // beforeEnter: checkLogin
    },
    {
      path: "/StLoginforWebinar/:id?",
      name: "StLoginforWebinar",
      component: () =>
        import(
          /* webpackChunkName: "StLoginforWebinar" */ "../components/Login/StLoginforWebinar.vue"
        ),
      meta: { layout: "anonymous", guest: true },
      beforeEnter: checkLogin,
    },
    {
      path: "/SubAccounts",
      name: "SubAccounts",
      component: () =>
        import(
          /* webpackChunkName: "SubAccounts" */ "../views/SubAccounts.vue"
        ),
      meta: { layout: "global", requiresAuth: true },
      beforeEnter: checkLogin,
    },
    {
      path: "/StudentClasses",
      name: "StudentClasses",
      component: () =>
        import(
          /* webpackChunkName: "StudentClasses" */ "../views/StudentClasses.vue"
        ),
      meta: { layout: "global", requiresAuth: true },
      children: [
        {
          name: "BookedClasses",
          path: "/BookedClasses",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "BookedClasses" */ "../components/StudenClasses/BookedClasses.vue"
              ),
          },
          meta: { layout: "global", requiresAuth: true },
        },
        {
          name: "BookedWebinars",
          path: "/BookedWebinars",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "BookedWebinars" */ "../components/StudenClasses/BookedWebinars.vue"
              ),
          },
          meta: { layout: "global", requiresAuth: true },
        },
        {
          path: "/StudentArchivedClasses",
          name: "StudentArchivedClasses",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "StudentArchivedClasses" */ "../components/StudenClasses/StudentArchivedClasses.vue"
              ),
          },
          meta: { layout: "global", requiresAuth: true },
          beforeEnter: checkLogin,
        },
        {
          path: "/manage-reservations",
          name: "ManageReservations",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "ManageReservations" */ "../views/Student/ManageReservations"
              ),
          },
          meta: { layout: "global", requiresAuth: true },
        },
        {
          name: "StudentQuizzes",
          path: "/student-quizzes",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "StudentQuizzes" */ "../views/Student/StudentQuizzes.vue"
              ),
          },
          beforeEnter: checkLogin,
          meta: { layout: "global", requiresAuth: true },
        },
        {
          path: "/student-homework",
          name: "StudentHomeworkList",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "StudentHomework" */ "../components/Dashboard/Homework.vue"
              ),
          },
          meta: { layout: "global", requiresAuth: true },
          beforeEnter: checkLogin,
        },
      ],
      meta: { layout: "global", requiresAuth: true },
      beforeEnter: checkLogin,
    },
    {
      path: "/TutorClasses",
      name: "TutorClasses",
      component: () =>
        import(
          /* webpackChunkName: "StudentClasses" */ "../views/StudentClasses.vue"
        ),
      meta: { layout: "global" },
      children: [
        {
          name: "CurrentClasses",
          path: "/CurrentClasses",

          components: {
            inner: () =>
              import(
                /* webpackChunkName: "CurrentClasses" */ "../components/TutorClasses/CurrentClasses.vue"
              ),
          },
          meta: { layout: "tutor", requiresAuth: true },
        },
        {
          path: "/TutorArchivedClasses",
          name: "TutorArchivedClasses",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "TutorArchivedClasses" */ "../components/TutorClasses/TutorArchivedClasses.vue"
              ),
          },
          meta: { layout: "tutor", requiresAuth: true },
          beforeEnter: checkLogin,
        },
        {
          path: "/tutor-homework",
          name: "TutorHomework",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "TutorHomework" */ "../components/Dashboard/Homework.vue"
              ),
          },
          meta: { layout: "tutor", requiresAuth: true },
          beforeEnter: checkLogin,
        },
      ],
      meta: { layout: "tutor", requiresAuth: true },
      beforeEnter: checkLogin,
    },
    {
      path: "/dashboard",
      name: "TutorsDashboard",
      component: () => import("../views/dashboard.vue"),
      meta: { layout: "global" },
      children: [
        {
          name: "NewTutors",
          path: "new-tutors",

          components: {
            inner: () =>
              import(
                /* webpackChunkName: "NewTutors" */ "../components/Dashboard/NewTutors.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "StudentsGroups",
          path: "students-groups",
          meta: { requiresAuth: true, layout: "global" },
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "StudentsGroups" */ "../components/Dashboard/StudentsGroups.vue"
              ),
          },
        },
        {
          name: "StudentGroupsDetails",
          path: "students-groups/:id",
          meta: { requiresAuth: true, layout: "global" },
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "StudentGroupsDetails" */ "../views/StudentGroupsDetails.vue"
              ),
          },
        },
        {
          name: "AssignStudents",
          path: "students-groups/:id/assign",
          meta: { requiresAuth: true, layout: "global" },
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "AssignStudent" */ "../components/Dashboard/AssignStudent.vue"
              ),
          },
        },
        {
          name: "CoursesManagement",
          path: "courses-managment",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "CoursesManagement" */ "../components/Dashboard/CoursesManagement.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "GroupClasses",
          path: "group-classes",
          meta: { layout: "global" },
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "GroupClasses" */ "../components/Dashboard/GroupClasses.vue"
              ),
          },
        },
        {
          name: "Classes",
          path: "classes",
          meta: { layout: "global" },
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "Classes" */ "../components/Dashboard/Classes.vue"
              ),
          },
        },
        {
          name: "studentClasses",
          path: "classes/student/:id",
          meta: { layout: "global" },
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "StudentClasses" */ "../components/Dashboard/StudentsList/StudentClasses"
              ),
          },
        },
        {
          path: "quizzes",
          name: "Quizzes",
          meta: { layout: "global" },
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "QuizView" */ "../views/QuizView.vue"
              ),
          },
        },
        {
          path: "quizzes/:name/questions/:id",
          name: "Questions",
          meta: { layout: "global" },
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "QuestionsView" */ "../views/QuestionsView.vue"
              ),
          },
        },
        {
          name: "AssignStudentGroup",
          path: "group-classes/:id/:ins/assign",
          meta: { layout: "global" },
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "AssignStudentGroup" */ "../components/Dashboard/AssignStudentGroup.vue"
              ),
          },
        },
        {
          name: "UnderReviewTutors",
          path: "under-review-tutors",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "UnderReviewTutors" */ "../components/Dashboard/UnderReviewTutors.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "ApprovedTutors",
          path: "approved-tutors",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "ApprovedTutors" */ "../components/Dashboard/ApprovedTutors.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "RejectedTutors",
          path: "rejected-tutors",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "RejectedTutors" */ "../components/Dashboard/RejectedTutors.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "TerminatedTutors",
          path: "terminated-tutors",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "TerminatedTutors" */ "../components/Dashboard/TerminatedTutors.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "StudentsList",
          path: "students",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "StudentsListView" */ "../views/StudentsList/StudentsListView.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "Homework",
          path: "homework",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "Homework" */ "../components/Dashboard/Homework.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "RescheduleRequests",
          path: "reschedule-requests",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "RescheduleRequests" */ "../components/Dashboard/rescheduleRequests/RescheduleRequests.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "AcceptedRequests",
          path: "accepted-requests",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "AcceptedRequests" */ "../components/Dashboard/rescheduleRequests/AcceptedRequests.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "RefusesRequests",
          path: "refused-requests",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "RefusedRequests" */ "../components/Dashboard/rescheduleRequests/RefusedRequests.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "OperatorWebinars",
          path: "webinars",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "OperatorWebinars" */ "../views/OperatorWebinars.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "ContactUsList",
          path: "contact-us-list",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "ContactUsList" */ "../views/ContactUsList.vue"
              ),
          },
          meta: { layout: "global" },
        },
        {
          name: "Feedbacks",
          path: "feedbacks",
          components: {
            inner: () =>
              import(
                /* webpackChunkName: "Feedbacks" */ "../views/Feedbacks.vue"
              ),
          },
          meta: { layout: "global" },
        },
      ],
    },
    {
      path: "/linkedIn",
      name: "linkedIn",
      component: () =>
        import(/* webpackChunkName: "linkedIn" */ "../views/linkedIn.vue"),
      meta: { layout: "anonymous", guest: true },
    },
    {
      path: "/verifyemail/:id",
      name: "verifyemail",
      component: () =>
        import(
          /* webpackChunkName: "verifyemail" */ "../views/verifyemail.vue"
        ),
      meta: { layout: "anonymous", guest: true },
    },
    {
      path: "/resetpassword/:id",
      name: "resetpassword",
      component: () =>
        import(
          /* webpackChunkName: "resetpassword" */ "../views/resetpassword.vue"
        ),
      meta: { layout: "anonymous", guest: true },
    },
    {
      path: "/About",
      name: "About",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
    {
      path: "/ContactUs",
      name: "ContactUs",
      component: () =>
        import(/* webpackChunkName: "ContactUs" */ "../views/ContactUs.vue"),
      meta: { layout: "global" },
      beforeEnter: checkLogin,
    },
    //{ path: "*", component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/PageNotFound.vue') }
    {
      //path: "*",
      path: "/:catchAll(.*)",
      name: "404*",
      component: require("../views/error404.vue").default, // load sync home
    },
  ],
  mode: "history",
  //const router = createRouter({
  //    history: createWebHistory(),
  //    routes,
  //}),
})
