const state = {
  selectedKidsCourses: [],
  selectedMatrix: [],
  webinar_id: null,
  selected_kid_program: null,
}

const getters = {
  GET_SELECTED_KIDS_COURSES(state) {
    return state.selectedKidsCourses
  },

  GET_SELECTED_MATRIX(state) {
    return state.selectedMatrix
  },

  GET_WEBINAR_ID(state) {
    return state.webinar_id
  },

  GET_SELECTED_KID_PROGRAM(state) {
    return state.selected_kid_program
  },
}

const mutations = {
  /**
   * Save ID of selected courses into state
   * @param payload Object {user: number, course, number, tutor: number}
   */
  SAVE_SELECTED_KIDS_COURSE(state, payload) {
    state.selectedKidsCourses.push(payload)
  },

  /**
   * Save Program ID
   * @param payload number
   */
  SAVE_KID_PROGRAM(state, payload) {
    state.selected_kid_program = payload
  },

  /**
   * Remove ID of course from selected courses
   * @param payload number
   */
  REMOVE_SELECTED_KIDS_COURSE(state, payload) {
    const index = state.selectedKidsCourses.findIndex(
      c => c.course.id === payload
    )
    state.selectedKidsCourses.splice(index, 1)
  },

  /**
   * Save Selected Matrix based on user_id, course_id and tutor_id
   * @param payload Object
   */
  SAVE_SELECTED_MATRIX(state, payload) {
    state.selectedMatrix.push(payload)
  },

  /**
   * Remove a matrix from Selected Matrix
   * @param payload Object {user_id, course_id}
   */
  REMOVE_SELECTED_MATRIX(state, payload) {
    const index = state.selectedMatrix.findIndex(
      m => m.course === payload.course && m.user === payload.user
    )
    state.selectedMatrix.splice(index, 1)
  },

  /**
   * Save new matrix to selected matrix
   * @param payload Array
   */
  SAVE_NEW_MATRIX(state, payload) {
    state.selectedMatrix = [...payload]
  },

  /**
   * Clear all selected matrices
   */
  CLEAR_SELECTED_MATRIX(state) {
    state.selectedMatrix = []
  },

  /**
   * Save webinar ID
   * @param payload number
   */
  SAVE_WEBINAR_ID(state, payload) {
    state.webinar_id = payload
  },

  /**
   * Remove webinar ID
   */
  REMOVE_WEBINAR_ID(state) {
    state.webinar_id = null
  },

  /**
   * Clear all selected courses
   */
  CLEAR_KIDS_DATA(state) {
    state.selectedKidsCourses = []
    state.selectedMatrix = []
    state.webinar_id = null
  },
}

const actions = {
  // Selected courses
  saveSelectedKidsCourse({ commit }, payload) {
    commit("SAVE_SELECTED_KIDS_COURSE", payload)
  },

  // Save Program
  saveKidsProgram({ commit }, payload) {
    commit("SAVE_KID_PROGRAM", payload)
  },

  // Remove Selected course by id
  removeSelectedKidsCourse({ commit }, payload) {
    commit("REMOVE_SELECTED_KIDS_COURSE", payload)
  },

  // Save Selected Object to Matrix
  saveSelectedMatrix({ commit }, payload) {
    commit("SAVE_SELECTED_MATRIX", payload)
  },

  // Replace existing matrix with new matrix
  replaceSelectedMatrix({ commit }, payload) {
    commit("CLEAR_SELECTED_MATRIX")
    commit("SAVE_NEW_MATRIX", payload)
  },

  // Remove Selected Object from Matrix
  removeSelectedMatrix({ commit }, payload) {
    commit("REMOVE_SELECTED_MATRIX", payload)
  },

  // Clear all selected matrix
  clearSelectedMatrix({ commit }, payload) {
    commit("CLEAR_SELECTED_MATRIX", payload)
  },

  // Save webinar id
  saveWebinar({ commit }, payload) {
    commit("SAVE_WEBINAR_ID", payload)
  },

  // Remove webinar id
  removeWebinar({ commit }) {
    commit("REMOVE_WEBINAR_ID")
  },

  // Clear all selected courses
  clearAllKidsSelections({ commit }) {
    commit("CLEAR_KIDS_DATA")
  },
}

export default { state, getters, mutations, actions }
