<template>
  <div class="alarqam-layout">
    <v-progress-linear
      v-if="isRouteLoading"
      indeterminate
      color="cyan"
      style="z-index: 2000; top: -97px"
    ></v-progress-linear>
    <Header></Header>
    <transition name="slide" mode="out-in">
      <slot></slot>
    </transition>
    <!-- Start Footer-->
    <!-- <Footer></Footer> -->
    <!-- End Footer-->
  </div>
</template>

<script>
import Header from "@/components/Header.vue"
// import Footer from '@/components/Footer.vue';
import GlobalMixin from "@/GlobalMixin.js"

export default {
  mixins: [GlobalMixin],
  name: "anonymous_layout",
  components: {
    Header,
    // Footer,
  },
  computed: {
    isRouteLoading() {
      return this.$store.getters.GET_IS_ROUTE_LOADING
    },
  },
}
</script>

<style scoped lang="scss">
/* Add a black background color to the top navigation */
.logo {
  width: 150px;
  height: 70px;

  .logo-container {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #04aa6d;
  color: white;
}

.slide-enter-active,
.slide-leave-active {
  transition:
    opacity 0.5s,
    transform 0.5s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-30%);
}

.logo {
  width: 5rem;
  height: 4rem;
}

.logo img {
  width: 100%;
  height: 100%;
}
</style>
