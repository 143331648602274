<template>
  <footer class="footer">
    <div class="container footer-content">
      <div class="footer-section footer-contact">
        <div>
          <!-- <div class="col-12 d-md-none pb-0">
            <router-link :to="{ name: 'FancyHome' }">
              <img :src="instituteLogo" alt="logo" class="logo" />
            </router-link>
          </div> -->
          <div class="about-alkuttab">
            <router-link :to="{ name: 'FancyHome' }">
              <img :src="instituteLogo" alt="logo" class="logo footer-logo" />
            </router-link>
            <!-- <h3 class="contact">{{ $t("footer.contactUs") }}</h3> -->
            <p class="description">
              {{ this.footerData.about }}
            </p>
            <div class="contact-section">
              <p class="call">
                {{ $t("footer.Call") }} :
                <a
                  class="phone"
                  :href="`tel:${this.footerData.whatsappNumber}`"
                >
                  {{ this.footerData.whatsappNumber }}
                </a>
              </p>
              <div class="contact-divider"></div>
              <p class="email">
                {{ $t("footer.Email") }} :
                <a :href="`mailto:${this.footerData.contactUs}`">
                  {{ this.footerData.contactUs }}
                </a>
              </p>
            </div>
            <p v-if="institute.id === 1" class="address">
              955 W John Carpenter FWY, Suite 100, Irving &nbsp;Tx&nbsp;75039
            </p>
            <div>
              <a
                v-for="social in socialLinks"
                :key="social.id"
                :href="social.website"
                target="_blank"
              >
                <img
                  :key="social.id"
                  :src="getSocialImage(social.logo)"
                  alt="Social icon"
                  class="social-img"
                />
              </a>
            </div>
            <!-- <div class="-flex" v-if="socialLinks.length">
            <a
              class="social-link-container"
              v-for="link in socialLinks"
              :key="link.id"
              :style="{ background: link.background }"
              :href="`${link.website}`"
              target="_blank"
            >
              <img
                :src="require(`../assets/images/footer/links/${link.logo}.svg`)"
                :alt="link.logo"
              />
            </a>
          </div> -->
          </div>
        </div>
        <hr class="line" />
        <div class="subscribe-section">
          <h3 class="subscribe">{{ $t("footer.Subscribe") }}</h3>
          <p class="subscribe-description">
            {{ this.footerData.subscribeText }}
          </p>
          <div class="subscribe-input">
            <input
              type="email"
              :placeholder="$t('footer.emailHere')"
              v-model="email"
            />
            <button @click.prevent="subscribe">
              {{ $t("footer.subscribeNow") }}
            </button>
          </div>
          <!-- <p class="d-none d-md-block">
            {{ $t("footer.Copyright") }}
          </p> -->
        </div>
      </div>
      <div class="footer-all">
        <div class="footer-container container">
          <!-- Render each section dynamically -->

          <div
            v-for="(section, index) in transformedFilters"
            :key="index"
            class="footer-section"
          >
            <h3 class="footer-title">{{ section.title }}</h3>
            <ul
              class="footer-list"
              :class="section.social ? 'social-links' : ''"
            >
              <li v-for="(item, idx) in section.links" :key="idx">
                <a
                  :href="item.url || item.website"
                  :target="item.newTap ? '_blank' : undefined"
                >
                  <!-- Render text for non-image and non-social links -->
                  <span v-if="!section.image && !section.social">{{
                    item.text
                  }}</span>

                  <!-- Render app images -->
                  <img
                    v-else-if="section.image"
                    class="app-img"
                    :src="item.image"
                    alt="Image description"
                  />

                  <!-- Render social images dynamically -->
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!-- Footer bottom row -->
        <hr />
        <div class="footer-bottom">
          <p class="copyright">
            &copy; Copyright Alkuttab, powered by Digitling Ltd.
          </p>

          <div class="footer-bottom-right">
            <span @click="openTermsAndConditions">Terms & Conditions</span>
            <span>Privacy policy</span>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :isOpened="modal"
      @closeModal="closeModal"
      :maxWidth="1000"
      :headerTitle="$t('footer.termsAndConditions')"
      hideDefaultFooter
      :minHeight="700"
      persistent
    >
      <template #body>
        <iframe :src="termsLink" class="w-100 h-100"></iframe>
      </template>
    </Modal>
  </footer>
</template>

<script>
import { socialLinks, websiteLinks } from "../data/footer"
import GlobalMixin from "@/GlobalMixin"
import { mapGetters } from "vuex"
import Modal from "./global/Modal.vue"
export default {
  name: "FooterComponent",
  mixins: [GlobalMixin],
  components: { Modal },

  data() {
    return {
      termsLink: null,
      userType: this.$store.getters.StateUser?.user?.user_type,
      configs: JSON.parse(localStorage.getItem("configs")),
      institute: JSON.parse(localStorage.getItem("institute_configs")),
      email: null,
      modal: false,

      socialLinks,
      websiteLinks,
      footerSections: [
        {
          title: "ABOUT US",
          links: [
            { text: "Who we are", url: "/about" },
            { text: "How to start", url: "#" },
            { text: "Work at Alkuttab!", url: "#" },
            { text: "Policy & Privacy", url: "#" },
          ],
        },

        {
          title: "LEARN",
          links: [
            { text: "Learn Arabic online", url: "/tutors?course-id=1" },
            { text: "Learn Quran online", url: "/tutors?course-id=2" },
            { text: "Learn Tajweed online", url: "/tutors?course-id=3" },
          ],
        },
        {
          title: "LANGUAGES",
          links: [
            { text: "Learn in Arabic", url: "#" },
            { text: "Learn in English", url: "#" },
            { text: "Learn in Urdu", url: "#" },
            { text: "Learn in French", url: "#" },
          ],
        },
        {
          title: "Tutor by Gender",
          links: [
            { text: "Male Tutors", url: "/tutors?genders=Male" },
            { text: "Female Tutors", url: "/tutors?genders=Female" },
          ],
        },

        {
          title: "FOR TUTORS",
          links: [
            { text: "Become an online tutor", url: "#" },
            { text: "Teach Arabic online", url: "#" },
            { text: "Teach Quran online", url: "#" },
            { text: "Teach Tajweed online", url: "#" },
          ],
        },

        {
          title: "TUTORS NEAR YOU",
          links: [
            { text: "Tutors in NYC", url: "#" },
            { text: "Tutors in Los Angeles", url: "#" },
            { text: "Tutors in Toronto", url: "#" },
            { text: "Tutors in London", url: "#" },
            { text: "Tutors in Singapore", url: "#" },
            { text: "Tutors abroad", url: "#" },
          ],
        },
        {
          title: "APPs",
          image: true,
          links: [
            {
              image: require("@/assets/images/appstore.png"),
              url: "#",
              newTap: true,
            },
            {
              image: require("@/assets/images/googleplay.png"),
              url: "#",
              newTap: true,
            },
          ],
        },
        {
          title: "Alkuttab Socials",
          social: true,
          links: socialLinks,
        },
      ],
    }
  },
  computed: {
    ...mapGetters("tutors", ["tutors"]), // Map the getter to access courses
    ...mapGetters("filters", ["filters"]), // Map the getter to access courses

    transformedFilters() {
      const titleMapping = {
        course_types: this.$t("footer.learn"), // "LEARN" -> "تعلم"
        language_ids: this.$t("footer.languages"), // "LANGUAGES" -> "اللغات"
        genders: this.$t("footer.tutorByGender"), // "Tutor by Gender" -> "المدرس حسب الجنس"
        country_ids: this.$t("footer.tutorsNearYou"), // "TUTORS NEAR YOU" -> "مدرسون بالقرب منك"
        class_sizes: this.$t("footer.classSize"), // "Class Size" -> "حجم الفصل"
      }

      // Create the base transformed array from filters
      const baseSections = this.filters
        .filter(filter => filter.api_param_name !== "class_sizes")
        .map(filter => ({
          title: titleMapping[filter.api_param_name] || filter.title,
          links: filter.options.slice(0, 10).map(option => ({
            text:
              filter.api_param_name === "course_types"
                ? `${this.$t("fancyHome.heroLearn")} ${option.title} ${this.$t("footer.online")}`
                : filter.api_param_name === "language_ids"
                  ? `${this.$t("footer.learnIn")} ${option.title}`
                  : filter.api_param_name === "genders"
                    ? `${option.title}`
                    : filter.api_param_name === "country_ids"
                      ? `${this.$t("footer.tutorsIn")} ${option.title}`
                      : `Option: ${option.title}`,
            url: `/tutors?${filter.api_param_name}=${option.value}`,
          })),
        }))

      // Add ABOUT US section at the beginning
      const forTutorsSection = {
        title: this.$t("footer.forTutors"), // Adjust the key as needed
        links: [
          { text: this.$t("footer.becomeOnlineTutor"), url: "/tutors" },
          { text: this.$t("footer.teachArabicOnline"), url: "/courses" },
          { text: this.$t("footer.teachQuranOnline"), url: "/courses" },
          { text: this.$t("footer.teachTajweedOnline"), url: "/courses" },
        ],
      }

      const aboutUsSection = {
        title: this.$t("footer.aboutUs"), // Adjust the key as needed
        links: [
          { text: this.$t("footer.whoWeAre"), url: "/about" },
          {
            text: this.$t("footer.howToStart"),
            url: "https://blog.alkuttab.io/",
          },
          {
            text: this.$t("footer.workAtAlkuttab"),
            url: "https://blog.alkuttab.io/",
          },
          { text: this.$t("footer.policyAndPrivacy"), url: "#" },
        ],
      }

      // Add APPs and Alkuttab Socials sections at the end
      const appsSection = {
        title: this.$t("footer.apps"), // Adjust the key as needed
        image: true,
        links: [
          {
            image: require("@/assets/images/appstore.png"),
            url: "https://apps.apple.com/us/app/alkuttab/id6474078678",
            newTap: true,
          },
          {
            image: require("@/assets/images/googleplay.png"),
            url: "https://play.google.com/store/apps/details?id=com.alkuttab.digitling",
            newTap: true,
          },
        ],
      }

      return [aboutUsSection, ...baseSections, forTutorsSection, appsSection]
    },
    instituteLogo() {
      return this.configs?.logo?.config_value
    },

    footerData() {
      return {
        whatsappNumber: this.configs?.whatsapp_number?.config_value,
        about: this.configs?.about?.config_value,
        contactUs: this.configs?.contactUs?.config_value,
        subscribeText: this.configs?.subscribe_text?.config_value,
      }
    },
  },
  mounted() {
    if (!localStorage.getItem("configs")) {
      this.getconfigurations().then(res => (this.configs = res))
    }
    this.websiteLinks.forEach(obj =>
      obj.links.forEach(link => {
        if (link.text === "Login" || link.text === "Register")
          return (link.loggedIn = this.userType)
      })
    )
  },
  methods: {
    getSocialImage(logo) {
      try {
        return require(`@/assets/images/footer/links/${logo}.svg`)
      } catch (error) {
        console.error(`Error loading logo: ${logo}`, error)
        return "" // Fallback if image is not found
      }
    },

    getUrls() {
      this.axios
        .get(`${process.env.VUE_APP_APIEndPoint}institutes/terms/`)
        .then(res => {
          this.termsLink = res.data.data.terms_and_conditions_url
        })
    },
    handleFunctionCall(method) {
      this[method]()
    },
    openTermsAndConditions() {
      this.getUrls()
      this.modal = true
    },
    openGoogleApps() {
      location.href =
        "https://play.google.com/store/apps/details?id=com.alkuttab.digitling"
    },
    isKuttabOnly(isKuttabOnly) {
      if (isKuttabOnly) {
        if (this.institute.id === 1) {
          return true
        }
        return false
      }
      return true
    },
    closeModal(value) {
      this.modal = value
    },
    subscribe() {
      if (!this.email) return this.showAlert("messages.emptyEmail", "error")

      const body = {
        email: this.email,
      }
      this.axios
        .post(
          `${process.env.VUE_APP_APIEndPoint}institutes/subscription/add/`,
          body,
          this.headers
        )
        .then(() => {
          this.showAlert(this.$t("messages.subscribed"))
          this.email = ""
        })
        .catch(err => this.formatErrors(err))
    },
  },
  watch: {
    "$i18n.locale"() {
      this.headers.headers["accept-language"] = localStorage.lang
      this.getconfigurations().then(res => {
        this.configs = res
        localStorage.setItem("configs", JSON.stringify(res))
        window.dispatchEvent(new Event("configs"))
      })
    },
  },
}
</script>

<style scoped lang="scss">
.about-alkuttab * {
  font-size: 14px;
  font-weight: 500;
}

.footer-logo {
  max-width: 230px;
  max-height: 150px;
  object-fit: cover;
}
.line {
  display: none;
}
.about-alkuttab .description {
  width: 75%;
  margin-top: 1rem;
}
.call > a,
.email > a {
  text-decoration: none;
  color: green;
  font-weight: 600;
}
.email,
.call {
  font-weight: 600;
}

.contact-divider {
  width: 1px;
  height: 30px;
  background: #ccc;
  margin: 0px 1rem 10px;
  @media (max-width: 768px) {
    display: none;
  }
}

.contact-section {
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.address {
  font-weight: 600;
  font-size: 14px;
}

.footer {
  background-color: #f8f8f8;
  color: rgb(55, 55, 55);
  padding-top: 40px;
  padding-bottom: 0px;
  font-family: "Nunito", sans-serif;
}

.footer-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
}
.footer-contact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 40px;
}
.footer-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin: 0 auto;
  padding: 0px;
}

.footer-section {
  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .social-links {
    display: flex;
  }
  .social-img {
    width: 35px;
    height: 35px;
    background: #e6eef8;
    padding: 8px;
    border-radius: 5px;
    margin-right: 1rem;
  }
  ul.footer-list {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 5px;
      font-size: 14px;
      a {
        color: rgb(55, 55, 55);
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: green;
          text-decoration: underline;
        }
      }
    }
  }
}

.subscribe-section {
  input {
    color: rgb(55, 55, 55);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.75);
    padding: 10px 20px;
    font-family: $font-family;
    font-weight: $sec-font-weight;
    font-size: $sec-font-size;
    line-height: 24px;
    flex: 1;
    font-size: 14px;
    font-weight: 600;
  }
  input:focus {
    outline: 2px solid #5fae32;
  }
  button {
    padding: 10px 20px;
    background: #5fae32;
    border-radius: 8px;
    color: #fff;
    font-family: $font-family;
    font-size: $sec-font-size;
    font-weight: 500;
    line-height: 24px;
    margin-top: 1rem;
    &:hover {
      background: darken($color: #5fae32, $amount: 5);
    }
  }
  .subscribe-input {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-bottom: 10px;
  .footer-bottom-right span {
    margin-left: 15px;
    font-weight: 600;
    font-size: 12px;
  }
  .footer-bottom-right span:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .copyright {
    padding: 0px;
    margin: 0px;
  }
}

.app-img {
  border-radius: 4px;
  border: 5px solid black;
  width: 150px;
}
@media (max-width: 1199px) {
  .footer-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .line {
    display: block;
    margin: 1.5rem 0px;
  }
  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }
  .footer-contact {
    grid-template-columns: repeat(1, 1fr);
  }
  .footer-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 480px) {
  .footer-container {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
    align-items: center;
    justify-items: center;
  }
}
</style>
