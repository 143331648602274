<template>
  <a
    class="lang-switch-btn"
    id="lang-btn"
    @click="changeLanguage"
    :title="$t('Change Language')"
  >
    <!-- <img :src="langPathImg" /> -->
    <v-icon :color="drawer ? 'black' : iconColor">mdi-web</v-icon>
    <span class="lang-text">{{ lang }}</span>
  </a>
</template>

<script>
import GlobalMixin from "@/GlobalMixin.js"

export default {
  name: "locale-changer",
  mixins: [GlobalMixin],
  props: ["isScrolled", "drawer"],
  mounted() {
    document.documentElement.lang = this.$i18n.locale
    document.documentElement.dir = this.$i18n.locale === "en" ? "ltr" : "rtl"
    this.$vuetify.rtl = this.$i18n.locale === "ar"
  },
  computed: {
    lang() {
      return this.$i18n.locale === "en" ? "EN" : "عربي"
    },
    iconColor() {
      if (this.drawer) return "rgb(56,56,56)"
      return this.isScrolled ? "rgb(56, 56, 56)" : "#fff"
    },
  },
  methods: {
    changeLanguage() {
      localStorage.setItem(
        "preferred-lang",
        this.$i18n.locale === "en" ? "ar" : "en"
      )
      this.changeLang()
    },
  },
}
</script>
<style scoped lang="scss">
.lang-switch-btn {
  display: flex;
  align-items: center;
}

.lang-text {
}
.lang-switch-btn:hover {
  i,
  .lang-text {
    color: $darker-green !important;
  }
}
.lang-switch-btn span {
  margin-left: 2px;
  font-weight: 600;
}
</style>
