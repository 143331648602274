//https://www.smashingmagazine.com/2020/10/authentication-in-vue-js
const state = {
  user: null,
  userAuthenticatedWPriviliges: false,
  unloggedUser: null,
  routeLoading: null,
}
const getters = {
  isAuthenticated: state => !!state.user,
  isAuthenticatedWPriviliges: state => state.userAuthenticatedWPriviliges,
  StateUser: state => state.user,
  getUnLoggedUser: state => state.unloggedUser,
  isStudent: state => state.user.user.category == "student",
  isTutor: state => state.user.user.category == "tutor",
  GET_IS_ROUTE_LOADING: state => state.routeLoading,
}
const actions = {
  StoreUser({ commit }, User) {
    commit("setUser", User)
  },
  UpdateUser({ commit }, User) {
    commit("updateUser", User)
  },
  RemoveCurrentUser({ commit }) {
    commit("removeUser")
  },
  toggleRouteLoading({ commit }, payload) {
    commit("TOGGLE_ROUTE_LOADING", payload)
  },
}
const mutations = {
  setUnloggedUser(state, user) {
    state.unloggedUser = user
  },
  setUser(state, user) {
    state.user = user
  },
  updateUser(state, user) {
    state.user.user = user
  },
  setuserAuthenticatedWPriviliges(state, userFlage) {
    state.userAuthenticatedWPriviliges = userFlage
  },
  removeUser(state) {
    state.user = null
  },
  TOGGLE_ROUTE_LOADING(state, payload) {
    state.routeLoading = payload
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
