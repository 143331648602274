<template>
  <v-dialog
    v-model="isOpened"
    :persistent="persistent"
    :max-width="maxWidth"
    :scrollable="scrollable"
    :fullscreen="fullscreen"
    :transition="transition || (fullscreen && 'dialog-bottom-transition')"
  >
    <v-card :style="{ 'border-radius': `${borderRadius}px` }">
      <!-- Header Part -->
      <v-card-title v-if="!hideDefaultHeader">
        <span class="text-h5"> {{ headerTitle }} </span>
        <v-spacer></v-spacer>
        <v-btn class="mx-2" small :color="theme" @click="closeModal">
          <v-icon dark> mdi-window-close </v-icon>
        </v-btn>
      </v-card-title>
      <slot name="header" v-else></slot>
      <!-- Body Part -->
      <div :style="{ height: minHeight + 'px' }">
        <slot name="body"></slot>
      </div>
      <!-- Footer Part -->
      <v-card-actions v-if="!hideDefaultFooter">
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="closeModal"> Cancel </v-btn>
        <v-btn color="green darken-1" text @click="confirmModal"> OK </v-btn>
      </v-card-actions>
      <slot name="footer" v-else></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Modal",
  props: {
    isOpened: {
      type: Boolean,
      required: true,
      default: false,
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 290,
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: false,
    },
    transition: {
      type: String,
      required: false,
      default: "",
    },
    minHeight: {
      type: Number,
      required: false,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDefaultHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      required: false,
      default: "primary",
    },
    headerTitle: {
      type: String,
      required: false,
      default: "Note",
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDefaultFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    borderRadius: {
      type: String,
      required: false,
      default: "0",
    },
  },
  data() {
    return {}
  },
  methods: {
    /**
     * Emits a false value to parnet component to mutate the dialog property to false
     */
    closeModal() {
      this.$emit("closeModal", false)
    },
    /**
     * Emits an event to parent component to do something
     */
    confirmModal() {
      this.$emit("confirmModal")
    },
  },
  watch: {
    /**
     * Watch for changes for isOpened prop
     * @param newVal boolean
     */
    isOpened(newVal) {
      if (!newVal) return this.closeModal()
    },
  },
}
</script>

<style lang="scss" scoped></style>
