import { Validator } from "vee-validate"

Validator.extend("full_name", {
  getMessage: () => "Full name field must contains at least 2 names.",
  validate: value => value.trim().split(" ").length >= 2,
})

Validator.extend("min_length", {
  getMessage: () => `Each name must have more that 2 characters.`,
  validate: (value, params) =>
    value
      .trim()
      .split(" ")
      .every(x => x.length >= params[0]),
})

Validator.extend("phone", {
  getMessage: () => "This field must contain a valid phone number.",
  validate: value => {
    const pattern = /^\+?([0-9]\s?){8,16}$/
    return pattern.test(value)
  },
})

Validator.extend("min_words", {
  getMessage: () => "This field must contain at least 3 words.",
  validate: (value, params) => value.trim().split(" ").length >= params[0],
})
