//https://www.smashingmagazine.com/2020/10/authentication-in-vue-js
const state = {
  selectedTutorId: null,
  selectedTutorData: null,
  selectedCourses: null,
  selectedProgram: null,
  discountData: null,
  selectedTimeSlots: null,
  selectedProgramDetails: null,
  inMeeting: null,
}
const getters = {
  getTutorData: state => state.selectedTutorData,
  getSelectedCourses: state => state.selectedCourses,
  getSelectedProgram: state => state.selectedProgram,
  getSelectedTutorId: state => state.selectedTutorId,
  getCoursesDiscount: state => state.discountData,
  getTimeSlots: state => state.selectedTimeSlots,
  getProgDetails: state => state.selectedProgramDetails,
  GET_IN_MEETING(state) {
    return state.inMeeting
  },
}
const actions = {
  toggleInMeeting({ commit }, payload) {
    commit("TOGGLE_IN_MEETING", payload)
  },
}
const mutations = {
  setProgDetails(state, data) {
    state.selectedProgramDetails = data
  },
  setTimeSlots(state, data) {
    state.selectedTimeSlots = data
  },
  setCoursesDiscount(state, data) {
    state.discountData = data
  },
  setSelectedTutorId(state, id) {
    state.selectedTutorId = id
  },
  setTutorData(state, selectedTutorData) {
    state.selectedTutorData = selectedTutorData
  },
  setCourses(state, selectedCourses) {
    state.selectedCourses = selectedCourses
  },
  setProgram(state, selectedProgram) {
    state.selectedProgram = selectedProgram
  },
  TOGGLE_IN_MEETING(state, payload) {
    state.inMeeting = payload
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
