//https://www.smashingmagazine.com/2020/10/authentication-in-vue-js/
import Vuex from "vuex"
import Vue from "vue"
import createPersistedState from "vuex-persistedstate"
import auth from "./modules/auth"
import classes from "./modules/classes"
import reservations from "./modules/reservations"
import kidsReservations from "./modules/kidsReservations"
import alerts from "./modules/alerts"
import tutors from "./modules/tutors"
import filters from "./modules/filters"
import tutorDetails from "./modules/tutorDetails"
// Load Vuex
Vue.use(Vuex)
// Create store
export default new Vuex.Store({
  modules: {
    auth,
    classes,
    reservations,
    kidsReservations,
    alerts,
    tutors,
    filters,
    tutorDetails,
  },
  plugins: [createPersistedState()],
})
