const state = {
  tutors: [], // Store the tutors locally
}

const mutations = {
  // Mutation to set the tutors in the state
  SET_TUTORS(state, tutors) {
    state.tutors = tutors
  },
}

const actions = {
  // Action to fetch tutors locally (with argument)
  getTutors({ commit }, tutors) {
    // Commit the mutation to update the state with the passed tutors
    commit("SET_TUTORS", tutors)
  },
}

const getters = {
  // Getter to access tutors from the state
  tutors: state => state.tutors,
}

export default {
  namespaced: true, // Namespace this module
  state,
  mutations,
  actions,
  getters,
}
