<template>
  <div class="zoom-layout">
    <transition name="slide" mode="out-in">
      <slot></slot>
    </transition>
  </div>
</template>
<script>
export default {
  name: "zoom_layout",
}
</script>
<style></style>
