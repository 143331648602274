const state = {
  selectedTutor: null,
  status: "idle", // Can be 'loading', 'success', or 'error'
  error: null, // Holds error messages, if any
}

const getters = {
  getSelectedTutorDetails(state) {
    return state.selectedTutor
  },
  getStatus(state) {
    return state.status
  },
  getError(state) {
    return state.error
  },
}

const mutations = {
  setSelectedTutorDetails(state, payload) {
    state.selectedTutor = payload
    state.status = "success"
    state.error = null
  },
  setStatus(state, payload) {
    state.status = payload
  },
  setError(state, payload) {
    state.error = payload
    state.status = "error"
  },
}

const actions = {
  /**
   * Action to set the selected tutor details.
   * @param {Function} commit
   * @param {Object} payload - Tutor details object
   */
  setTutor({ commit }, payload) {
    commit("setSelectedTutorDetails", payload)
  },

  /**
   * Action to set the status.
   * @param {Function} commit
   * @param {String} payload - Status string (e.g., 'loading', 'success', 'error')
   */
  setStatus({ commit }, payload) {
    commit("setStatus", payload)
  },

  /**
   * Action to set an error message.
   * @param {Function} commit
   * @param {String} payload - Error message string
   */
  setError({ commit }, payload) {
    commit("setError", payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
