const userType = JSON.parse(localStorage.getItem("vuex"))?.auth?.user?.user
  ?.user_type
const configs = JSON.parse(localStorage.getItem("configs"))

const backgrounds = [
  {
    name: "facebook",
    background: "#1877F214",
  },
  {
    name: "insta",
    background: "#FF000014",
  },
  {
    name: "linkedin",
    background: "#0E76A814",
  },
  {
    name: "twitter",
    background: "#1DA1F214",
  },
  { name: "youtube", background: "#FF000014" },
]

let socialLinks = []
if (configs?.followUs) {
  socialLinks = Object.entries(configs?.followUs?.config_value).map(
    ([key, link], index) => {
      const obj = backgrounds.find(
        background => key.replace("Link", "") === background.name
      )
      return {
        id: index + 1,
        background: obj.background,
        logo: obj.name,
        website: link,
      }
    }
  )
}

const websiteLinks = [
  {
    size: 5,
    links: [
      {
        id: 1,
        text: "Home",
        componentName: "Home",
        loggedIn: false,
        type: "text",
        method: { exists: false, name: "" },
      },
      {
        id: 2,
        text: "About",
        componentName: "About",
        loggedIn: false,
        type: "text",
        method: { exists: false, name: "" },
      },
      {
        id: 3,
        text: "Tutors",
        componentName: "Tutors",
        loggedIn: false,
        type: "text",
        method: { exists: false, name: "" },
      },
      {
        id: 4,
        text: "Courses",
        componentName: "KidsCoursesList",
        loggedIn: false,
        type: "text",
        method: { exists: false, name: "" },
      },
      {
        id: 5,
        text: "joinFreeHalaqa",
        componentName: "Webinars",
        loggedIn: false,
        type: "text",
        method: { exists: false, name: "" },
      },
    ],
  },
  {
    size: 5,
    links: [
      {
        id: 1,
        text: "Login",
        componentName: "Login",
        loggedIn: userType,
        type: "text",
        method: { exists: false, name: "" },
      },
      {
        id: 2,
        text: "Register",
        componentName: "Register",
        loggedIn: userType,
        type: "text",
        method: { exists: false, name: "" },
      },
      {
        id: 3,
        text: "termsAndConditions",
        componentName: "/",
        loggedIn: false,
        type: "text",
        method: { exists: true, name: "openTermsAndConditions" },
      },
      {
        id: 4,
        text: "https://www.qscan.com.au/wp-content/uploads/2023/07/google-play-download-android-app-logo-png-transparent.png",
        componentName: "/",
        loggedIn: false,
        type: "image",
        method: { exists: true, name: "openGoogleApps" },
        isKuttabOnly: true,
      },
      // {
      //   id: 4,
      //   text: "News",
      //   componentName: "/",
      //   loggedIn: false,
      //   method: { exists: false, name: "" },
      // },
      // {
      //   id: 5,
      //   text: "Alkuttab community",
      //   componentName: "/",
      //   loggedIn: false,
      //   method: { exists: false, name: "" },
      // },
    ],
  },
]

export { socialLinks, websiteLinks }
