<template>
  <!--<a :href="href" class="btn btn-light border border-dark"> <img src="@/assets/img/linkedin.png" width="35" height="35">SignUp with LinkedIn</a>-->
  <a class="social-item linkedin" :href="href">
    <v-btn class="w-100 mb-3" color="white">
      <svg
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 2.14748C0 1.23812 0.761304 0.5 1.69977 0.5H21.2993C22.2381 0.5 22.999 1.23812 22.999 2.14748V21.8518C22.999 22.7614 22.2381 23.499 21.2993 23.499H1.69977C0.761393 23.499 0 22.7615 0 21.8521V2.14722V2.14748Z"
          fill="#0A66C2"
        />
        <path
          d="M6.99015 19.7469V9.39269H3.54856V19.7469H6.99051H6.99015ZM5.27007 7.97924C6.46998 7.97924 7.217 7.18416 7.217 6.19053C7.19454 5.17426 6.46998 4.40137 5.29289 4.40137C4.115 4.40137 3.3457 5.17426 3.3457 6.19044C3.3457 7.18407 4.09245 7.97915 5.24752 7.97915H5.2698L5.27007 7.97924ZM8.89512 19.7469H12.3364V13.9653C12.3364 13.6562 12.3589 13.3464 12.4498 13.1256C12.6985 12.5071 13.2648 11.8668 14.2157 11.8668C15.4607 11.8668 15.9591 12.8162 15.9591 14.2083V19.7469H19.4003V13.8101C19.4003 10.6299 17.7027 9.14995 15.4384 9.14995C13.5821 9.14995 12.7668 10.1875 12.3138 10.8942H12.3367V9.39305H8.8953C8.94022 10.3644 8.89503 19.7473 8.89503 19.7473L8.89512 19.7469Z"
          fill="white"
        />
      </svg>
      <span class="ms-2">{{ $t("continueWithLinkedin") }}</span>
    </v-btn>
  </a>
</template>
<script>
export default {
  data() {
    return {
      href: "",
    }
  },
  name: "linkedlogin",
  components: {},
  created() {
    let self = this
    //console.log("window.location.origin" + window.location.origin);

    self.href =
      "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=" +
      process.env.VUE_APP_LinkedAppId +
      "&redirect_uri=" +
      process.env.VUE_APP_ProdctionURL +
      "/linkedIn&state=OKqERc1PX7KEQ6k7&scope=r_liteprofile%20r_emailaddress"
  },
}
</script>
