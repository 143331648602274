const state = {
  showAlert: false,
  alertIcon: "",
  alertTitle: "",
  alertText: "",
  alertTimer: 0,
  // isAlertPermanent: false,
}

const getters = {
  GET_SHOW_ALERT(state) {
    return state.showAlert
  },

  GET_ALERT_ICON(state) {
    return state.alertIcon
  },

  GET_ALERT_TITLE(state) {
    return state.alertTitle
  },

  GET_ALERT_TEXT(state) {
    return state.alertText
  },

  GET_ALERT_TIMER(state) {
    return state.alertTimer
  },

  // GET_IS_ALERT_PERMANENT(state) {
  //   return state.isAlertPermanent;
  // },
}

const mutations = {
  SET_SHOW_ALERT(state, payload) {
    state.showAlert = payload
  },

  SET_ALERT_ICON(state, payload) {
    state.alertIcon = payload
  },

  SET_ALERT_TITLE(state, payload) {
    state.alertTitle = payload
  },

  SET_ALERT_TEXT(state, payload) {
    state.alertText = payload
  },

  SET_ALERT_TIMER(state, payload) {
    state.alertTimer = payload
  },

  // SET_IS_ALERT_PERMANENT(state, payload) {
  //   state.isAlertPermanent = payload;
  // },
}

const actions = {
  toggleAlert({ commit }, payload) {
    commit("SET_SHOW_ALERT", payload)
  },

  setAlertIcon({ commit }, payload) {
    commit("SET_ALERT_ICON", payload)
  },

  setAlertTitle({ commit }, payload) {
    commit("SET_ALERT_TITLE", payload)
  },

  setAlertText({ commit }, payload) {
    commit("SET_ALERT_TEXT", payload)
  },

  setAlertTimer({ commit }, payload) {
    commit("SET_ALERT_TIMER", payload)
  },

  // setIsAlertPermanent({ commit }, payload) {
  //   commit("SET_IS_ALERT_PERMANENT", payload);
  // },
}

export default { state, getters, mutations, actions }
